@import "../../variables";
@import "../../mixins";
@import "../../functions";

.ddd-checkbox {
  min-width: 28px;
  .mdc-checkbox {
    width: $checkboxSize;
    height: $checkboxSize;
    .mat-ripple {
      display: none;
    }
    .mdc-checkbox__ripple {
      display: none;
    }
  }
  .mdc-label {
    color: $checkboxLabelColor;
    font-weight: $checkboxLabelFontWeight;
    font-size: $checkboxLabelFontSize;
    line-height: $checkboxLabelLineHeight;
    .tag-select-option-label {
      font-weight: 200;
    }
  }
  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      border: 1px solid $cg-mid-grey-3;
      border-radius: 4px;
    }
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
      @include themify() {
        border-color: themed("primary");
        background-color: themed("primary");
      }
    }
    &:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    &:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    &:not(:disabled):active
      .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
      ~ .mdc-checkbox__background {
      @include themify() {
        border-color: themed("primary");
        background-color: themed("primary");
      }
    }
    .mdc-checkbox__checkmark-path {
      stroke-width: 3px;
      transform: scale(0.6) translate(7px, 7px);
    }
    .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: #fff;
    }
  }
  &.mat-mdc-checkbox-disabled {
    .mdc-checkbox {
      .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
        color: $cg-mid-grey-3;
      }
      .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
      .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
      .mdc-checkbox__native-control[data-indeterminate="true"][disabled] ~ .mdc-checkbox__background {
        border-color: $cg-mid-grey-3;
        background-color: $cg-light-grey-3;
        border-width: 1px;
      }

      .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
        ~ .mdc-checkbox__background {
        border-color: $cg-mid-grey-3;
        background-color: $cg-light-grey-3;
        border-width: 1px;
      }
    }
  }
  &.custom-label {
    .mdc-label {
      font-weight: 200;
      font-size: 14px;
    }
  }
}
