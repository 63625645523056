@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

button {
  &.tabler-icon {
    border: 1px solid $cg-mid-grey-3;
    border-radius: 20px;
    background-color: transparent;
    height: 24px;
    padding: 3px;
    font-size: 16px;
    cursor: pointer;
    @include themify() {
      color: themed("button-icon-light-color");
    }
  }
}

.tabler-icon {
  font: 18px tabler-icons;

  @include themify() {
    color: themed("icon-color");
  }
  &--export::before {
    content: "\f799";
  }
  &--chevron-right::before {
    content: "\ea61";
  }
  &--chevron-down::before {
    content: "\ea5f";
  }
  &--device-floppy::before {
    content: "\eb62";
  }
  &--circle-x::before {
    content: "\ea6a";
  }
  &--circle-check::before {
    content: "\ea67";
  }
  &--help-circle::before {
    content: "\f91d";
  }
  &--history::before {
    content: "\ebea";
  }
  &--info-circle::before {
    content: "\eac5";
  }
  &--plus::before {
    content: "\eb0b";
  }
  &--file-arrow-left::before {
    content: "\f033";
  }
  &--trash::before {
    content: "\eb41";
  }
  &--pencil::before {
    content: "\eb04";
  }
  &--send::before {
    content: "\eb1e";
  }
  &--alert-triangle::before {
    content: "\ea06";
  }
  &--grip-vertical::before {
    content: "\ec01";
  }
  &--file-export::before {
    content: "\ede9";
  }
  &--mail::before {
    content: "\eae5";
  }
  &--call::before {
    content: "\eb09";
  }
  &--dots-vertical::before {
    content: "\ea94";
  }
  &--users::before {
    content: "\ebf2";
  }
  &--share::before {
    content: "\eb21";
  }
  &--share-off::before {
    content: "\f1a1";
  }
  &--file-download::before {
    content: "\ea9d";
  }
  &--settings::before {
    content: "\eb20";
  }
  &--chart-bar::before {
    content: "\ea59";
  }
  &--search::before {
    content: "\eb1c";
  }
  &--file-text::before {
    content: "\eaa2";
  }
  &--eye::before {
    content: "\ea9a";
  }
  &--reload::before {
    content: "\f3ae";
  }
  &--star::before {
    content: "\eb2e";
  }
  &--star-filled::before {
    content: "\f6a6";
  }
  &--file-plus::before {
    content: "\eaa0";
  }
  &--folder::before {
    content: "\eaad";
  }
  &--lock::before {
    content: "\eae2";
  }
  &--cloud-upload::before {
    content: "\ea75";
  }
  &--x::before {
    content: "\eb55";
  }
  &--refresh::before {
    content: "\eb13";
  }
  &--filter-off::before {
    content: "\ed2c";
  }
  &--arrow-left::before {
    content: "\ea19";
  }
  &--alert::before {
    content: "\ea05";
  }
  &--calendar-event::before {
    content: "\ea52";
  }
  &--circle-plus::before {
    content: "\ea69";
  }
  &--circle-minus::before {
    content: "\ea68";
  }
  &--bell-off::before {
    content: "\ece9";
  }
  &--file-description::before {
    content: "\f028";
  }
  &--repeat-off::before {
    content: "\f18e";
  }
  &--circle::before {
    content: "\ea6b";
  }
  &--user-plus::before {
    content: "\eb4b";
  }

  &--theme-color::before {
    @include themify() {
      color: themed("primary");
    }
  }

  &--rounded {
    @include themify() {
      color: themed("primary");
      border: 1px solid themed("icon-border");
    }
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    padding-left: 4px;
    padding-top: 1px;
    font-size: 14px;
  }

  &--red::before {
    color: $cg-red-6;
  }

  &--green::before {
    color: $cg-green-6;
  }

  &--amber::before {
    color: $cg-orange-5;
  }

  &--grey::before {
    color: $cg-mid-grey-8;
  }

  &--mid-grey-5::before {
    color: $cg-mid-grey-5;
  }

  &--mid-grey-6::before {
    color: $cg-mid-grey-6;
  }

  &--general::before {
    @include themify() {
      color: themed("primary");
    }
  }

  &--dark-grey-6::before {
    color: $cg-dark-grey-6;
  }

  &--big::before {
    font: 32px tabler-icons;
  }

  &--medium::before {
    font: 25px tabler-icons;
  }

  &--large::before {
    font: 50px tabler-icons;
  }

  &--action-menu::before {
    font: 23px tabler-icons;
    line-height: 30px;
    width: 30px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }
}
