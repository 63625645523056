@import "../../mixins";
@import "../../variables";

.main-color {
  @include themify() {
    color: themed("old-design-main-color");
  }
}
.secondary-color {
  @include themify() {
    color: themed("old-design-secondary-color");
  }
}
.main-border-color {
  @include themify() {
    color: themed("old-design-main-color");
  }
}
.main-bg-color {
  @include themify() {
    background-color: themed("old-design-main-color");
  }
}
.main-bg-color-opacity {
  @include themify() {
    background-color: themed("old-design-main-color-rgba");
  }
}
.brand-only {
  &-hide {
    @include themify() {
      display: themed("display-ddd-icon-only");
    }
  }
}
.ddd-only {
  &-hide {
    @include themify() {
      display: themed("display-brand-icon-only");
    }
  }
}
/* Styles for header and nav */
.common-header {
  .ddd-logo {
    margin-left: 15px;
    @include themify() {
      display: themed("display-ddd-logo-box");
    }
  }
  .menu-dropdown {
    label {
      @include themify() {
        @include setIcon("user", themed("old-design-main-color"));
      }
      background-repeat: no-repeat;
      background-position: 12px center;
    }
  }
}
.main-nav nav {
  .nav-item,
  .item-menu {
    &.active {
      @include themify() {
        background-color: themed("old-design-main-color");
        color: themed(white-base);
      }
      font-weight: bold;
    }
    &:hover {
      @include themify() {
        background-color: themed("old-design-main-color");
        color: themed(white-base);
      }
    }
  }
  .nav-item .sub-link {
    color: $cg-dark-grey-6;
  }

  ul li {
    ul {
      @include themify() {
        border-color: themed("old-design-main-color");
        background: themed("old-design-main-color-nav-menu-rgba");
      }
    }
    &:hover {
      @include themify() {
        background-color: themed("old-design-main-color");
      }
    }
  }
}
/* Styles for footer */
.footer-container {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  margin: 0 -55px;
  @include themify() {
    border-top: themed("footer-border-width") solid;
    border-color: themed("old-design-main-color");
    background-color: themed("footer-background-color");
    color: themed("footer-font-color");
  }
  a {
    @include themify() {
      color: themed("footer-font-color");
    }
  }
  .footer-img-container {
    @include themify() {
      background-image: themed("footer-logo");
    }
  }
}
.step-circle {
  background: #b8b8b8;
  &.custom {
    &:after {
      border-left-color: #b8b8b8;
    }
  }
  &.active {
    @include themify() {
      background: themed("old-design-main-color");
    }
    &:after {
      @include themify() {
        border-left-color: themed("old-design-main-color");
      }
    }
  }
  &.complete {
    background: #00cec1;
    &:after {
      border-left-color: #00cec1;
    }
  }
}
/* Styles for form elements */
.setup-form {
  .input-form,
  .select-form {
    border: 1px solid $cg-mid-grey-3;
    &:focus {
      @include themify() {
        border-color: $cg-purple-4;
      }
    }
  }
  button {
    &.btn-confirm {
      @include themify() {
        background-color: themed("old-design-main-color");
      }
    }
  }
}
.custom-form {
  .input-form,
  .select-form {
    border: 1px solid $cg-mid-grey-3;
    &:hover {
      &:not(:disabled) {
        @include themify() {
          border-color: themed("input-selected-border-color");
        }
      }
    }
    &:focus {
      @include themify() {
        border-color: themed("input-selected-border-color");
      }
    }
  }

  .ng-select {
    &.ng-select-opened {
      & > .ng-select-container {
        @include themify() {
          border-color: themed("input-selected-border-color");
        }
      }
    }
  }
}
.instruction {
  .title {
    @include themify() {
      color: themed("old-design-main-color");
    }
  }
}

[type="radio"] {
  &:checked {
    & + label {
      &:after {
        @include themify() {
          background: themed("old-design-main-color");
        }
      }
    }
  }
  &:not(:checked) {
    & + label {
      &:after {
        @include themify() {
          background: themed("old-design-main-color");
        }
      }
    }
  }
}
/* Styles for datepicker */
.mat-datepicker-toggle-active {
  @include themify() {
    color: themed("old-design-main-color");
  }
}
/* Styles for table */
#table-header {
  @include themify() {
    background-color: themed("white-base");
  }
  font-weight: bold;
  color: #000;
}
/* Styles for assign page */
.group-header {
  .releaser-info-container {
    color: $cg-dark-grey-6;
  }
}
.section-expansion-panel {
  .persons-wrapper {
    padding: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    .person-item {
      margin: 1px 0;
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
    .assigned-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $cg-mid-grey-7;
      margin-right: 5px;
    }
  }
  .person-item {
    color: $cg-mid-grey-7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: #fff;
    border: 1px solid $cg-mid-grey-3;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
    &:not(:last-child) {
      margin-right: 3px;
    }

    &.logged-user {
      font-weight: 600;
      color: $cg-dark-grey-6;
    }
  }
  .remove-assign {
    &:hover {
      &:before {
        color: $cg-mid-grey-8;
      }
    }
    &:before {
      color: $cg-dark-grey-3;
    }
  }
}
.colleague-item {
  .name {
    color: $cg-dark-grey-6;
  }
  .email {
    color: $cg-mid-grey-7;
  }
  .colleague-action {
    .tabler-icon {
      color: $cg-mid-grey-6;
    }
  }
}
/* Styles for qc page */
.question-box {
  textarea,
  .narrative {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $cg-mid-grey-3;
    &:focus {
      @include themify() {
        border-color: themed("input-selected-border-color");
      }
    }
  }
}
.section-item {
  color: $cg-mid-grey-7;
  &.active {
    color: $cg-dark-grey-6;
    &:not(.complete) {
      &:before {
        content: "";
        position: absolute;
        left: 3px;
        top: 4px;
        width: 6px;
        height: 6px;
        border: none;
        border-radius: 10px;
        @include themify() {
          background: themed("old-design-main-color");
        }
      }
    }
  }

  &.complete {
    &:before {
      position: absolute;
      left: -3px;
      color: $cg-green-6;
      font: 18px tabler-icons;
      content: "\ea67"; //circle-check
    }
  }
}
.group-actions-container {
  .group-reject {
    &:hover {
      @include themify() {
        color: themed("primary-light");
      }
    }
  }
}
/* Styles for custom checkbox */
.checkbox-custom-right {
  &:checked {
    & + span {
      &:before {
        background: #fff;
      }
      &:after {
        @include themify() {
          background: themed("old-design-main-color");
          border-color:  themed("old-design-main-color");
        }
      }
    }

    &:disabled {
      & + span {
        color: $cg-mid-grey-3;
        &:before {
          background: $cg-light-grey-3;
        }
        &:after {
          background: $cg-mid-grey-3;
          border-color: $cg-mid-grey-3;
        }
      }

      &.auditor-rating-selected + span {
        &:before {
          background-color: $cg-auditor-selected;
        }
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }
}

.checkbox-review-rating-satisfactory {
  & + span {
    &:before {
      background: #fff;
      border: none; // Remove border
      box-shadow: 0 0 0 2px #fff; // Add outer border effect
    }
    &:after {
      @include themify() {
        border-width: 4px;
        border-color: #46a35e;
      }
    }
  }

  &:disabled {
    & + span {
      color: $cg-mid-grey-3;
      &:before {
        background: $cg-light-grey-3;
      }
      &:after {
        background: $cg-mid-grey-3;
        border-color: $cg-mid-grey-3;
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }

  &:checked {
    & + span {
      &:before {
        background: #fff;
        border: none;
        box-shadow: 0 0 0 2px #fff; 
      }
      &:after {
        @include themify() {
          background: #46a35e;
          border-color: #46a35e;
        }
      }
    }
    
    &:disabled {
      & + span {
        color: $cg-mid-grey-3;
        &:before {
          background: $cg-light-grey-3;
        }
        &:after {
          background: $cg-mid-grey-3;
          border-color: $cg-mid-grey-3;
        }
      }
  
      &.auditor-rating-selected + span {
        &:before {
          background-color: $cg-auditor-selected;
        }
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }
}

.checkbox-review-rating-needs-improvement {
  & + span {
    &:before {
      background: #fff;
      border: none;
      box-shadow: 0 0 0 2px #fff; 
    }
    &:after {
      @include themify() {
        border-width: 4px;
        border-color: #ff8333;
      }
    }
  }

  &:disabled {
    & + span {
      color: $cg-mid-grey-3;
      &:before {
        background: $cg-light-grey-3;
      }
      &:after {
        background: $cg-mid-grey-3;
        border-color: $cg-mid-grey-3;
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }

  &:checked {
    & + span {
      &:before {
        border: none;
        box-shadow: 0 0 0 2px #fff; 
      }
      &:after {
        @include themify() {
          background: #ff8333;
          border-color: #ff8333;
        }
      }
    } 
    
    &:disabled {
      & + span {
        color: $cg-mid-grey-3;
        &:before {
          background: $cg-light-grey-3;
        }
        &:after {
          background: $cg-mid-grey-3;
          border-color: $cg-mid-grey-3;
        }
      }
  
      &.auditor-rating-selected + span {
        &:before {
          background-color: $cg-auditor-selected;
        }
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }
}

.checkbox-review-rating-unsatisfactory {
  & + span {
    &:before {
      background: #fff;
      border: none;
      box-shadow: 0 0 0 2px #fff; 
    }
    &:after {
      @include themify() {
        border-width: 4px;
        border-color: #f14d6b;
      }
    }
  }

  &:disabled {
    & + span {
      color: $cg-mid-grey-3;
      &:before {
        background: $cg-light-grey-3;
      }
      &:after {
        background: $cg-mid-grey-3;
        border-color: $cg-mid-grey-3;
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }

  &:checked {
    & + span {
      &:before {
        border: none;
        box-shadow: 0 0 0 2px #fff; 
      }
      &:after {
        @include themify() {
          background: #f14d6b;
          border-color: #f14d6b;
        }
      }
    }  
    
    &:disabled {
      & + span {
        color: $cg-mid-grey-3;
        &:before {
          background: $cg-light-grey-3;
        }
        &:after {
          background: $cg-mid-grey-3;
          border-color: $cg-mid-grey-3;
        }
      }
  
      &.auditor-rating-selected + span {
        &:before {
          background-color: $cg-auditor-selected;
        }
      }
    }

    &.auditor-rating-selected + span {
      &:before {
        background-color: $cg-auditor-selected;
      }
    }
  }
}

.checkbox-custom-left {
  &:checked {
    & + span {
      &:before {
        @include themify() {
          background: themed("old-design-main-color");
          border-color: themed("old-design-main-color");
        }
      }
      &:after {
        background: #fff;
      }
    }

    &:disabled {
      & + span {
        color: $cg-mid-grey-3;
        &:after {
          background: $cg-light-grey-3;
        }
        &:before {
          background: $cg-mid-grey-3;
          border-color: $cg-mid-grey-3;
        }
      }
    }
  }
}
.checkbox-container {
  .checkmark {
    @include themify() {
      border-color: $cg-mid-grey-3;
      background-color: themed("white-base");
    }

    &:after {
      border-style: solid;
      border-color: $cg-light-grey-1;
    }
  }

  & input:checked ~ .checkmark {
    @include themify() {
      background-color: themed("primary");
      border-color: themed("primary");
    }
  }

  &:hover {
    & input ~ .checkmark {
      @include themify() {
        border-color: $cg-mid-grey-5;
        background-color: $cg-light-grey-3;
      }
    }

    & input:checked ~ .checkmark {
      @include themify() {
        border-color: themed("primary-light");
        background-color: themed("primary-light");
      }
    }
  }
}
.new-checkbox-container {
  .checkmark:after {
    border: solid;
    @include themify() {
      border-color: themed("white-base");
    }
  }
}
mat-expansion-panel.guide-expansion-panel {
  mat-panel-title {
    color: $cg-dark-grey-6;
  }
  .guide-panel-header {
    .quick-guide-icon {
      @include themify() {
        background-color: themed("icon-bg-color");
      }
      &:before {
        font: 18px tabler-icons;
        content: "\ea39"; //book
        @include themify() {
          color: themed("old-design-main-color");
        }
      }
    }
  }
}
/* Styles for dashboard component */
.dashboard-description {
  @include themify() {
    color: themed("old-design-main-color");
  }
}
.actions-to-take-header,
.header {
  color: $cg-dark-grey-6;
}
.issues-counter {
  @include themify() {
    color: themed("old-design-main-color");
  }
}
/* Styles for template manager */
.template-change-help-message {
  @include themify() {
    border: 1px solid themed("primary");
    background-color: themed("icon-bg-color");
  }
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 36px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}
/* Styles for info icon */
.info-icon {
  color: $cg-mid-grey-5;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  min-width: 14px;
  border-radius: 50%;
  cursor: pointer;

  &:before {
    font: 18px tabler-icons;
    content: "\eac5"; //info-circle
  }
}
/* Styles for arrow in mat table */
.mat-sort-header-container {
  .mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0) !important;
    color: #b8b8b8;
  }
  &.mat-sort-header-sorted {
    .mat-sort-header-arrow {
      opacity: 1 !important;
      @include themify() {
        color: themed("old-design-main-color");
      }
    }
  }
}

.mat-mdc-form-field.mat-warn .mat-mdc-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: $cg-red-6;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $cg-red-6;
}

.mat-calendar-body-selected {
  @include themify() {
    background-color: themed("primary");
    color: #fff;
  }
  &.mat-calendar-body-today {
    @include themify() {
      background-color: themed("primary");
    }
  }
}

.general-section {
  /*Styles for section mat accordion */
  .section-expansion-panel {
    .panel-header {
      &:before {
        @include themify() {
          color: themed("old-design-main-color");
        }
      }
    }
    &.mat-expanded {
      .panel-header {
        color: $cg-dark-grey-6;
        &:before {
          @include themify() {
            color: themed("old-design-main-color");
          }
          margin-top: -9px;
          content: "\ea5f"; //chevron-down
        }
      }
    }

    &.mat-expanded {
      .group-expansion-panel.mat-expanded {
        .panel-header.group-panel-header {
          &:before {
            @include themify() {
              color: themed("old-design-main-color");
            }
            margin-top: -9px;
            content: "\ea5f"; //chevron-down
          }
        }
      }
    }
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
      background: transparent;
    }
  }

  /*Styles for guide mat accordion */
  .guide-expansion-panel {
    .guide-panel-header {
      &:before {
        font: 18px tabler-icons;
        content: "\ea61"; //chevron-right
        position: absolute;
        right: 6px;
        top: 50%;
        margin-top: -9px;
        line-height: 18px;
        @include themify() {
          color: themed("old-design-main-color");
        }
      }
      &:after {
        content: "";
        position: absolute;
        right: 3px;
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        border: 1px solid $cg-light-grey-6;
        border-radius: 50%;
      }
    }

    &.mat-expanded {
      .guide-panel-header {
        color: $cg-dark-grey-6;

        &:before {
          right: 6px;
          margin-top: -9px;
          content: "\ea5f"; //chevron-down
        }
      }
    }
  }
}

.section-content {
  .multiple {
    .multiple-text {
      color: $cg-dark-grey-6;
    }
    .assign-counter {
      color: $cg-mid-grey-7;
    }
  }
}

.history-expansion-panel {
  .history-panel-header {
    &:before {
      @include themify() {
        color: themed("old-design-main-color");
      }
    }
    .tabler-icon--history {
      color: $cg-mid-grey-6;
      background-color: $cg-light-grey-3;
    }
  }
}

.earlier-history-expansion-panel {
  .earlier-history-panel-header {
    &:before {
      @include themify() {
        color: themed("old-design-main-color");
      }
    }
    .tabler-icon--history {
      color: $cg-mid-grey-6;
      background-color: $cg-light-grey-3;
    }
  }
}

/* Global styles for scrollbar */
::-webkit-scrollbar {
  height: 1rem;
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: $cg-light-grey-5;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background-color: $cg-light-grey-7;
  border-radius: 25px;
}

/* Global styles for mat-progress-bar material component*/
mat-progress-bar {
  @include themify() {
    .mdc-linear-progress__buffer-bar {
      background-color: themed("primary-light-2");
    }
    .mdc-linear-progress__bar-inner {
      border-color: themed("primary");
    }
  }
}

.arrow-container {
  @include themify() {
    color: themed("old-design-main-color");
  }
  .right-arrow {
    @include themify() {
      color: themed("old-design-main-color");
    }
  }
}

.critical-timeline-container {
  & .progress-bar-container {
    & .progress-bar {
      @include themify() {
        border-left: 2px solid themed("old-design-main-color");
        border-right: 2px solid themed("old-design-main-color");
        border-color: themed("old-design-main-color");
      }
      & progress {
        @include themify() {
          border-color: themed("old-design-main-color");
        }
      }
    }
  }
}

.doc-info {
  .doc-name {
    &::before {
      @include themify() {
        color: themed("old-design-main-color");
      }
    }
  }
}

.view-distributors {
  @include themify() {
    color: themed("old-design-main-color");
  }
}

.content {
  table:not(.cg-table-custom) {
    .mat-mdc-row:nth-child(odd) {
      .mat-mdc-cell {
        background-color: $cg-light-grey-5;
      }
      .mat-mdc-cell:first-child {
        border-radius: 4px 0 0 4px;
      }
      .mat-mdc-cell:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

.pad-wrapper {
  &:hover {
    @include themify() {
      border-color: themed("input-selected-border-color");
    }
  }
}

.content-box {
  margin-bottom: 30px;
}
.content-box-title {
  color: $cg-dark-grey-6;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 25px;
  padding-top: 5px;
  vertical-align: middle;
  &:before {
    display: inline-block;
    height: 28px;
    width: 28px;
    @include themify() {
      background-color: themed("icon-bg-color");
    }
    border-radius: 50%;
    padding: 6px 0 0 7px;
    font: 16px tabler-icons;
    content: "\ea39";
    @include themify() {
      color: themed("old-design-main-color");
    }
    margin-right: 10px;
  }
}

.actions-bar-box {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions-bar {
  margin: 0 -55px -12px;
  padding: 18px 55px;
  .select-action {
    height: 36px;
    width: 180px;
    border-radius: 3px;
  }
  .other-actions {
    padding: 10px;
  }
}

.analytics-section {
  font-family: "Haffer";
  margin-top: 15px;

  .chart-content {
    display: flex;
  }
  .chart-box {
    width: 60%;
  }
  .chart-control {
    margin-left: 50px;
    .count-box {
      &.count-released {
        cursor: pointer;
      }
      &.empty {
        text-decoration: none;
        cursor: auto;
      }
    }
  }

  /*Styles for chart checkbox */
  .new-checkbox-container {
    display: block;
    position: relative;
    margin-left: 10px;
    margin-right: 5px;
    z-index: 100;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: transparent;
    border: 0;
    height: 20px;
    width: 20px;
    padding: 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      top: 0;
      height: 20px;
      margin: 0;
      width: 20px;
      z-index: 100;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #c1c1c1;
    background-color: #fff;
    border-radius: 3px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .new-checkbox-container {
    input:checked ~ .checkmark:after {
      display: block;
    }
    input:checked ~ .checkmark {
      @include themify() {
        background-color: themed("primary");
      }
      border: none;
    }

    .checkmark:after {
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  /*Styles for stages table */
  .st-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 4px;
  }
  .st-sub-table {
    box-shadow: 0 0 0 1px $cg-mid-grey-3;
    border-radius: 4px;

    td {
      padding: 10px 0;
    }
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
      }
    }
    .st-sub-row {
      background: rgba(238, 239, 238, 0.3);
      &.release-stage-row {
        background: rgba(227, 239, 252, 0.3);
      }
      &.independent-review-stage-row {
        background: none;
      }
    }
    &.closed {
      .st-sub-row {
        display: none;
      }
      .sub-main-label {
        &::after {
          border-width: 5px 5px 0 5px;
          border-color: #093466 transparent transparent transparent;
        }
      }
    }
  }
  .grand-total-table,
  .grand-total-rationale-table {
    td {
      padding: 10px 0;
    }
  }
  .grand-total-table {
    .grand-total-row {
      background: #e3effc;
    }
  }
  .grand-total-rationale-table {
    box-shadow: none;
    border: none;
    tr {
      border-bottom: 1px solid #e2e2e2;
      &:first-child {
        border-top: 1px solid #e2e2e2;
      }
      &:first-child {
        &.grand-total-rationale-row {
          border: none;
        }
      }
    }
    .st-sub-row {
      background: none;
    }
    .grand-total-rationale-row {
      background: #fbe6e6;
    }
  }
  .st-separator {
    tr {
      height: 10px;
    }
  }
  .st-head-row {
    th {
      font-size: 13px;
      line-height: 18px;
      font-weight: 200;
      padding-bottom: 20px;
      &.main {
        font-weight: 500;
        text-align: left;
        width: 350px;
      }
    }
  }
  .st-count-box {
    display: flex;
    justify-content: center;
  }
  .sub-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
  }
  .sub-main-label {
    font-weight: 500;
    position: relative;
    cursor: pointer;
  }
  .sub-inner-label {
    font-weight: 500;
    padding-left: 42px;
  }
  .sub-final-label {
    color: #093466;
  }

  .grand-total-rationale-table {
    box-shadow: none;
    border: none;
    tr {
      border-bottom: 1px solid #e2e2e2;
      &:first-child {
        border-top: 1px solid #e2e2e2;
      }
      &:first-child {
        &.grand-total-rationale-row {
          border: none;
        }
      }
    }
    .st-sub-row {
      background: none;
    }
    .grand-total-rationale-row {
      background: #fbe6e6;
    }
    td {
      padding: 10px 0;
    }
  }

  .table-collapse-block {
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 0 0 1px $cg-mid-grey-3;
    border-radius: 4px;

    &:has(table) {
      padding-bottom: 14px;
    }

    .table-collapse {
      cursor: auto;
      margin: 0 14px;
      width: calc(100% - 28px);
    }

    .table-label {
      padding-left: 10px;
      width: 18%;
      font-weight: 600;
      color: #093466;
    }

    .st-count-box {
      display: flex;
      justify-content: center;
    }

    .table-click-header {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      padding: 14px 0;
      align-items: center;
    }
  }

  .count-box {
    height: 30px;
    width: 50px;
    border-radius: 5px;
    text-decoration: underline;
    font-size: 13px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    &.no-response {
      color: #7c7c7d;
      border: 1px solid #7c7c7d;
      background-color: rgba(230, 230, 230, 0.4);
    }
    &.count-finalized {
      color: #093466;
      border: 1px solid #093466;
      background-color: rgba(9, 52, 102, 0.2);
    }
    &.count-p5 {
      color: #64a71e;
      border: 1px solid #64a71e;
      background-color: rgba(100, 167, 30, 0.2);
    }
    &.count-p4 {
      color: #b99000;
      border: 1px solid #ffd84e;
      background-color: rgba(255, 216, 78, 0.2);
    }
    &.count-p3 {
      color: #0987b3;
      border: 1px solid #0987b3;
      background-color: rgba(9, 135, 179, 0.2);
    }
    &.count-p2 {
      color: #f1963a;
      border: 1px solid #f1963a;
      background-color: rgba(241, 150, 58, 0.2);
    }
    &.count-p1 {
      color: #df0d0d;
      border: 1px solid #df0d0d;
      background-color: rgba(223, 13, 13, 0.2);
    }
    &.count-total {
      color: #7c7c7d;
      border: 1px solid #7c7c7d;
      background-color: rgba(124, 124, 125, 0.2);
    }
    &.grand-total {
      color: #0585ff;
      border: 1px solid #0585ff;
      background-color: rgba(5, 133, 255, 0.2);
    }
    &.grand-total-sum {
      color: #7c7c7d;
      font-size: 15px;
    }
    &.count-rationale {
      color: #df0d0d;
      border: 1px solid #df0d0d;
      background-color: rgba(223, 13, 13, 0.2);
    }
    &.count-released-total {
      text-decoration: none;
      cursor: auto;
    }
    &.grand-total-count {
      text-decoration: none;
      cursor: auto;
    }
    &.empty {
      border: none;
      text-decoration: none;
      cursor: auto;
    }
  }
  .no-data-message {
    font-size: 13px;
    padding: 20px 20px;
  }
}

.table-icon {
  margin-left: 20px;
  padding-left: 3px;
  transition: transform 0.2s ease-in-out;
}

.st-sub-table:not(.closed) .table-icon {
  transform: rotate(90deg);
}
/* Styles for progress bar */
progress[value] {
  &.branded {
    &::-webkit-progress-value {
      @include themify() {
        background-color: themed("primary");
      }
    }

    /* For Firefox */
    &::-moz-progress-bar {
      @include themify() {
        background-color: themed("primary");
      }
    }

    @include themify() {
      color: themed("primary");
    }
  }
}
.banner-message {
  padding: 20px;
  border: 1px solid;
  border-radius: 3px;
  display: flex;
  margin: 20px 0;
  color: $cg-dark-grey-6;
  @include themify() {
    background-color: themed("icon-bg-color");
  }
  @include themify() {
    border-color: themed("primary");
  }
  .message {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }
  a {
    text-decoration: underline;
  }
  .info-icon {
    display: inline-block;
    text-align: center;
    margin: 0 8px 0 2px;
  }

  .message-container {
    display: flex;
    flex-direction: column;
  }

  .message-title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .icon-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: middle;
    padding-right: 20px;
  }
}

.deadline-box {
  @include themify() {
    background-color: themed("icon-bg-color");
  }
  @include themify() {
    border: 1px solid themed("primary");
  }
}

.spinner-container,
.documents-spinner-box {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    @include themify() {
      stroke: themed("primary");
    }
  }
}

.nav-item.sub-link {
  color: $cg-dark-grey-6;
}

.folder-breadcrumbs-box {
  .folder-item {
    &.middle-dreadcrumb {
      @include themify() {
        color: themed("primary");
      }
    }
    .more-folders {
      .dropdown {
        .dropdown-item {
          @include themify() {
            color: themed("primary");
          }
          &:hover {
            @include themify() {
              background-color: themed("icon-bg-color");
            }
          }
        }
      }
    }
  }
}

.distributors-count {
  @include themify() {
    background-color: themed("primary");
    color: themed("white-base");
  }
}
