@import "../../variables";
@import "../../mixins";
@import "../../functions";

$bd-color: rgba(238, 239, 238, 0.3);
$bg-color-2: #eeefee;

.ddd-page__backdrop {
  margin: 0 -55px;

  &.dark {
    background-color: $bd-color;
  }

  &.dark-2 {
    background-color: $bg-color-2;
  }

  .ddd-page__content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 15px;
  }
}
