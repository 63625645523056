@import "../../variables";
@import "../../mixins";
@import "../../functions";
@import "./mixin";

.ddd-slider {
  &.dx-rangeslider {
    .dx-slider-wrapper {
      .dx-slider-bar {
        .dx-slider-range {
          .dx-slider-handle {
            width: 8px;
            height: 8px;
            border-width: 3px;
            margin-left: -4px;
            margin-top: -6px;
            border-radius: 50%;
          }

          @include themify() {
            background: themed("primary");

            .dx-slider-handle {
              background-color: white;
              border-color: themed("primary");
            }
          }
        }
      }
    }
  }
}
