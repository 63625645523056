@use "ddd-styles/variables";
@import "./mixins";

.ddd-table__container {
  .ddd-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    font-size: 15px;
    line-height: 22px;
    @include ddd-table-colorize();

    thead {
      tr {
        th {
          border: none;
          font-size: 13px;
          line-height: 18px;
          color: variables.$cg-dark-grey-6;
          padding: 0 16px;
          background-clip: padding-box;

          & > div {
            min-width: 150px;
          }

          &.long {
            & > div {
              min-width: 250px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        box-shadow: none;
        border: 1px solid variables.$cg-mid-grey-3;
        background-color: #ffffff;
        border-radius: 4px;

        &.mdc-data-table__row:last-child {
          .mat-mdc-cell {
            border-bottom: 1px solid variables.$cg-mid-grey-3;
          }
        }

        td {
          border-style: solid;
          border-color: variables.$cg-mid-grey-3;
          border-top-width: 1px;
          border-bottom-width: 1px;
          border-left-width: 0;
          border-right-width: 0;
          height: 72px;
          padding: 0 16px;
          background-clip: padding-box;

          & > div {
            min-width: 150px;
          }

          &.long {
            & > div {
              min-width: 250px;
            }
          }

          &:last-child {
            border-right-width: 1px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          &:first-child {
            border-left-width: 1px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &.mat-mdc-table-sticky {
            z-index: 10 !important;
          }
        }
      }
    }
  }
}
