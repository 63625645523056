@import "./functions";

// Defining values
$small-range: (0em, 40em); /* 0, 640px */
$medium-range: (40.063em, 64em); /* 641px, 1024px */
$large-range: (64.063em, 90em); /* 1025px, 1440px */
$xlarge-range: (90.063em, 120em); /* 1441px, 1920px */
$xxlarge-range: (120.063em); /* 1921px */
// Defining media queries
$screen: "all" !default;
// $screen:       "only screen" !default;
$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;
$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;
$bpMediaCollection: (
  "screen": $screen,
  "landscape": $landscape,
  "small-up": $small-up,
  "small-only": $small-only,
  "medium-up": $medium-up,
  "medium-only": $medium-only,
  "large-up": $large-up,
  "large-only": $large-only,
  "xlarge-up": $xlarge-up,
  "xlarge-only": $xlarge-only,
  "xxlarge-up": $xxlarge-up,
  "xxlarge-only": $xxlarge-only
);
@mixin setBreakpoint($name) {
  @media #{map-get($bpMediaCollection, $name)} {
    @content;
  }
}
