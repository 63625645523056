@import "../../variables";
@import "../../mixins";
@import "../../functions";
@import "./mixin";

.ddd-stepper {
  .mat-step-header {
    pointer-events: none;

    &.cdk-keyboard-focused,
    &.cdk-program-focused {
      background-color: transparent;
    }

    .mat-step-label {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
    }

    .mat-step-optional {
      color: $cg-light-grey-7;
    }

    .mat-step-icon {
      width: 30px;
      height: 30px;
      font-size: 13px;
      letter-spacing: 0;
      background-color: transparent;
      color: $cg-mid-grey-3;
      border: 1.5px solid $cg-mid-grey-3;
      font-weight: 500;
    }
    .mat-step-icon-selected {
      background-color: transparent;
      color: $cg-dark-grey-6;
      border: 1.5px solid $cg-dark-grey-6;
    }
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background-color: transparent;
      color: $cg-green-6;
      border: 1.5px solid $cg-green-6;
    }
    .mat-step-icon-state-done {
      color: transparent;
      .mat-step-icon-content {
        &:before {
          font: 16px tabler-icons;
          content: "\ea5e"; //icon-check;
          color: $cg-green-6;
          margin-left: 16px;
        }
      }
    }
  }

  &.low-contrast {
    .mat-step-header {
      .mat-step-label-active {
        color: $cg-mid-grey-6;
      }
      .mat-step-label-selected {
        font-weight: 700;
        color: $cg-dark-grey-6;
      }
    }
    &.ddd-stepper-finished {
      .mat-step-label-selected {
        font-weight: 500;
        color: $cg-mid-grey-6;
      }
    }
  }
}
