.ddd-button-group {
  display: flex;

  .d-100 {
    .ddd-button {
      width: 100%;
    }
  }

  .ddd-button {
    &:not(:first-child) {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      margin-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
}
