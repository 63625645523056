@import "ddd-styles/mixins";
@import "../../variables";
@import "../../breakpoints";
@import "./mixin";

%overflow-visible {
  .mat-mdc-dialog-container {
    overflow: visible;
  }
}

.ddd-dialog-backdrop {
  background-color: $dialogBackdropColor;
}

.ddd-dialog {
  max-width: calc(100vh - 60px);
  border-radius: $dialogBorderRadius;

  @each $key, $sizeMap in $dialogSizeMap {
    &.#{$key} {
      @each $sizeKey, $sizeVal in $sizeMap {
        @include setBreakpoint($sizeKey) {
          width: $sizeVal;
        }
      }
    }
  }

  & > .mat-mdc-dialog-container {
    padding: 0;
    box-shadow: none;
  }

  &.overflow-visible {
    @extend %overflow-visible;
  }

  .ddd-dialog-header {
    height: 61px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 5px 6px;
    align-items: center;

    .dialog-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: $cg-dark-grey-6;
      display: block;
      margin: 14px 0 14px 15px;
      letter-spacing: 0;
    }

    .close-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 15px;
      @include themify() {
        color: themed("primary");
      }
      &:hover {
        cursor: pointer;
        @include themify() {
          color: themed("primary-light");
        }
      }
    }
  }

  .ddd-dialog-main {
    max-height: 80vh;
    overflow-y: auto;

    .dialog-message {
      word-break: break-word;
      color: $cg-dark-grey-6;
      font-family: Open-Sans-Regular, sans-serif;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      padding: 20px 20px 40px;
      &.before-dialog-content {
        padding: 20px;
      }
    }
  }

  .ddd-dialog-content {
    padding: 15px 20px;
    &.with-table {
      padding-top: 0;
    }
  }

  .ddd-dialog-separator {
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $cg-light-grey-6;
  }

  .ddd-dialog-footer {
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 10px;

    &.end {
      justify-content: flex-end;
    }
  }
}

.ddd-dialog__overflow-visible {
  @extend %overflow-visible;
}
