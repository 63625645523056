@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

.ddd-scrollbar {
  @include themify() {
    &::-webkit-scrollbar {
      height: 0.5rem;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #eee;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #778fa9;
      border-radius: 8px;
    }
  }
}

.cg-small-scrollbar {
  &::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
  }
}
