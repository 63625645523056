@import "../../variables";
@import "../../mixins";
@import "./../../functions";

@mixin ddd-table-colorize() {
  @include themify() {
    color: themed("grey-dark-2") !important;

    .color-primary {
      color: themed("primary") !important;
    }

    .color-primary-light {
      color: themed("primary-light-2") !important;
    }

    .color-depressed {
      color: themed("grey-dark") !important;
    }

    .mat-sort-header-sorted {
      color: themed("primary") !important;
      font-weight: bold;

      .mat-sort-header-arrow {
        color: themed("primary") !important;

        .mat-sort-header-stem {
          color: themed("primary") !important;
        }

        .mat-sort-header-indicator {
          color: themed("primary") !important;
        }
      }
    }
  }
}
