@import "../../variables";
@import "../../mixins";
@import "../../functions";

.ddd-input-group {
  &.w-100 {
    width: 100%;

    .ddd-input {
      width: 100%;
    }
  }

  &.icon-append {
    &.icon-inside {
      position: relative;

      .ddd-input {
        padding-right: 35px;
      }
      .ddd-icon {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: inherit;
      }
    }
  }

  &.icon-prepend {
    &.icon-inside {
      position: relative;

      .ddd-input {
        padding-left: 49px;
      }

      .ddd-icon {
        position: absolute;
        left: 5px;
        top: 5px;
        cursor: inherit;
      }
    }
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  .ddd-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    font-family: Haffer, sans-serif;
    display: inline-block;
    color: $cg-dark-grey-6;
  }
}
