@import "variables";
@import "./functions";

@mixin themify() {
  @each $theme, $map in $themes {
    .#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(
          $map: map-get($themes, $theme),
          $key: "#{$key}"
        );
        $theme-map: map-merge(
          $map1: $theme-map,
          $map2: (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin addFontDefault() {
  font-family: "Open Sans", sans-serif;
}

@mixin onHover() {
  &:hover,
  &:focus,
  &.active {
    &:not([disabled]):not(.disabled) {
      @content;
    }
  }
}

@mixin onEdge() {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin setIcon($type, $color) {
  $colorKey: "${color}";
  $encodedColor: str-replace("" + $color, "#", "%23");
  $url: getIconUrl($type);
  $urlWithPassedColor: str-replace($url, $colorKey, $encodedColor);
  background-image: url($urlWithPassedColor);
  @content;
}
