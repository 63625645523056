@import "../../variables";
@import "../../mixins";
@import "../../functions";

$cursors: (
  "auto",
  "default",
  "none",
  "context-menu",
  "help",
  "pointer",
  "progress",
  "wait",
  "cell",
  "crosshair",
  "text",
  "vertical-text",
  "alias",
  "copy",
  "move",
  "no-drop",
  "not-allowed",
  "all-scroll",
  "col-resize",
  "row-resize",
  "zoom-in",
  "zoom-out",
  "grab",
  "grabbing"
);

.ddd-typography-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  margin: 0;
  padding: 8px 0;
  color: $cg-dark-grey-6;
  text-transform: capitalize;
}

.ddd-typography-caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: $cg-dark-grey-6;
}

* {
  @include themify() {
    @each $key, $color in $theme-map {
      &.ddd-color--#{$key} {
        color: $color !important;
      }

      &.ddd-background--#{$key} {
        background-color: $color !important;
      }
    }
  }
}

@each $cur in $cursors {
  .ddd-cursor-#{$cur} {
    cursor: #{$cur} !important;
  }
}

@font-face {
  font-family: "Open-Sans-Regular";
  src: url("/assets/fonts/Haffer-Regular.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Regular.woff") format("woff"), url("/assets/fonts/Haffer-Regular.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Italic";
  src: url("/assets/fonts/Haffer-RegularItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-RegularItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-RegularItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Bold";
  src: url("/assets/fonts/Haffer-Bold.woff2") format("woff2"), url("/assets/fonts/Haffer-Bold.woff") format("woff"),
    url("/assets/fonts/Haffer-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Light";
  src: url("/assets/fonts/Haffer-Light.woff2") format("woff2"), url("/assets/fonts/Haffer-Light.woff") format("woff"),
    url("/assets/fonts/Haffer-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Light-Italic";
  src: url("/assets/fonts/Haffer-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-LightItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-LightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
