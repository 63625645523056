@use "ddd-styles/variables";
@import "ddd-styles/mixins";

/* Global styles for mat-slider */
.mat-mdc-slide-toggle.mat-warn.mat-mdc-slide-toggle-checked .mat-ripple-element {
  @include themify() {
    background-color: themed("primary");
  }
}
.mat-mdc-slide-toggle.mat-warn,
.mat-mdc-slide-toggle.mat-warn.mat-mdc-slide-toggle-checked {
  .mdc-switch:enabled .mdc-switch__track::after {
    @include themify() {
      background-color: themed("icon-bg-color");
    }
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    @include themify() {
      background: themed("primary");
    }
  }
}
