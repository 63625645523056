@use "ddd-styles/variables";

/* Global styles for toasts(alerts) */
.cg-tab-panel {
  &.dx-tabpanel {
    &.additional-margin {
      margin: 0 -28px;
      .dx-tabpanel .dx-multiview-wrapper {
        padding: 20px 13px 0;
      }
    }
    &.dx-state-focused {
      > .dx-tabpanel-tabs .dx-tabs {
        box-shadow: none;
      }
      > .dx-tabpanel-tabs .dx-tab {
        &.dx-state-focused {
          box-shadow: none;
        }
        &.dx-state-selected {
          box-shadow: none;
        }
      }
    }
    > .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
      box-shadow: none;
      border-bottom: 2px solid $cg-dark-grey-6;
    }
    > .dx-tabpanel-tabs .dx-tab.dx-state-hover {
      border-bottom: 2px solid $cg-dark-grey-6;
    }
    .dx-item {
      &.dx-tab {
        width: 200px;
        border-bottom: 2px solid $cg-light-grey-6;
      }
    }
    & > .dx-tabpanel-tabs .dx-tab {
      &.dx-tab-selected {
        .dx-tab-content {
          color: $cg-dark-grey-6;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          .tabler-icon {
            margin-right: 10px;
            color: $cg-dark-grey-6;
          }
        }
      }
      .dx-tab-content {
        color: $cg-mid-grey-6;
        .tabler-icon {
          margin-right: 10px;
          color: $cg-mid-grey-6;
        }
      }
    }
  }
  > .dx-tabpanel-tabs .dx-tabs {
    box-shadow: none;
    background-color: #fff;
  }
}
