@use "../../variables";

.cg-custom-form {
  .label {
    flex-basis: 41%;
    justify-content: flex-start;
    text-align: left;
    color: $cg-mid-grey-7;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    &.with-asterisc {
      align-items: flex-start;
      flex-direction: column;
      line-height: 40px;
      .asterisc-text {
        font-size: 11px;
        font-weight: 200;
        line-height: 10px;
      }
    }
    &.align-center {
      justify-content: center;
      line-height: 40px;
    }
  }

  .control-box {
    flex-basis: 59%;
    max-width: 59%;
    textarea {
      height: 90px;
      outline: none;
      padding: 10px 24px;
      width: 100%;
      resize: none;
      overflow: auto;
    }
  }

  .static-value {
    display: flex;
    min-height: 40px;
    align-items: center;
    color: $cg-dark-grey-6;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }

  .form-separator {
    margin: 15px -28px;
    border-bottom: 1px solid $cg-light-grey-6;
    &.no-bottom-margin {
      margin-bottom: 0;
    }
  }

  .control-group {
    &.separator-effect {
      margin-bottom: 0;
    }
    &.control-group-65-35 {
      .label {
        flex-basis: 65%;
      }

      .control-box {
        flex-basis: 35%;
        max-width: 35%;
      }
    }
    &.column-direction {
      flex-direction: column;
      .label {
        flex-basis: 100%;
        line-height: 40px;
      }

      .control-box {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}
