@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

$cg-button-hex-width: 32px;
$cg-body-font-size: 1rem;
@media only screen and (max-width: 1200px) {
  .c-header__body {
    .nav-item,
    .item-menu {
      padding: 0 10px;
    }
  }
  .c-header__username,
  .c-header__company {
    max-width: 120px;
  }
}
@media only screen and (min-width: 1201px) {
  .c-header__body {
    .nav-item,
    .item-menu {
      padding: 0 16px;
    }
  }

  .c-header__username,
  .c-header__company {
    max-width: 200px;
  }
}
.c-header {
  @include themify() {
    background-color: themed("header-background-color");
    border-bottom: 1px solid themed("header-border-color");
  }
  margin: 0 -55px;
  min-height: 56px;
}

.c-header__wrapper > div,
.c-header__wrapper > a,
.c-header__wrapper > img {
  padding-right: 24px;
}

.c-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 16px;
  height: 100%;
}

.c-header__logo {
  width: 140px;
  min-width: 140px;
  height: 50px;
  background-position: left;
  background-repeat: no-repeat;
  @include themify() {
    background-image: themed("header-logo");
  }
}

.c-header__body {
  flex-grow: 4;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 40px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  nav {
    display: flex;
    align-items: center;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  .nav-item,
  .item-menu {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    height: 40px;
    @include themify() {
      color: themed("header-menu-item-text-color");
    }
    justify-content: flex-start;
  }
  .dropdown {
    li {
      &:first-child {
        &::after {
          content: "";
          position: absolute;
          top: -11px;
          width: 100%;
          height: 11px;
          left: 0;
        }
      }
    }
  }

  .nav-item:hover,
  .nav-item.active {
    @include themify() {
      color: themed("header-menu-item-text-color-active");
    }
  }

  .item-menu {
    span {
      position: relative;
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        border-radius: 10px;
        right: -15px;
        top: 50%;
        margin-top: -2.5px;
        border-width: 5px 5px 0 5px;
        @include themify() {
          border-color: themed("header-menu-item-text-color") transparent transparent transparent;
        }
      }
    }
    &.active {
      span {
        @include themify() {
          color: themed("header-menu-item-text-color-active");
        }
        &:after {
          border-width: 5px 5px 0 5px;
          @include themify() {
            border-color: themed("header-menu-item-text-color-active") transparent transparent transparent;
          }
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  li {
    @include themify() {
      color: themed("header-menu-item-text-color-active");
    }
    display: block;
    float: left;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
    .sub-link {
      @include themify() {
        color: themed("header-menu-item-text-color");
      }
      &:hover {
        @include themify() {
          color: themed("header-menu-item-text-color-active");
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }

  .parent-list-item {
    &:hover {
      .item-menu {
        @include themify() {
          color: themed("header-menu-item-text-color-active");
        }
        span {
          &:after {
            border-width: 0 5px 5px 5px;
            @include themify() {
              border-color: transparent transparent themed("header-menu-item-text-color-active") transparent;
            }
          }
        }
      }
    }
    &.with-dropdown {
      padding-right: 8px;
      .dropdown {
        min-width: 122px;
        z-index: 1;
      }
    }
  }

  ul li {
    ul {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: all 0.5s ease;
      left: 0;
      display: none;
      top: 50px;
      border-radius: 0 0 4px 4px;
      border: 1px solid;
      @include themify() {
        border-color: transparent themed("header-dropdown-menu-border-color")
          themed("header-dropdown-menu-border-color") themed("header-dropdown-menu-border-color");
      }
    }
    &:hover > ul {
      visibility: visible;
      opacity: 1;
      display: block;
    }
    ul {
      &:hover {
        visibility: visible;
        opacity: 1;
        display: block;
      }
      li {
        clear: both;
        width: 100%;
        @include themify() {
          background-color: themed("header-dropdown-menu-background-color");
        }
        padding-left: 6px;
        &:last-child {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
}

.c-header__user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  gap: 6px;
}

.c-header__username,
.c-header__company {
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-header__username {
  @include themify() {
    color: themed("header-username-text-color");
  }
}

.c-header__company {
  @include themify() {
    color: themed("header-company-text-color");
  }
}

.c-button--hex-container {
  width: $cg-button-hex-width;
  height: calc($cg-button-hex-width * 1.1875);
  position: relative;
  margin-top: 2px;
}

.c-button--hex-container:hover {
  @include themify() {
    background-color: themed("header-hex-box-color-hover");
    border-color: themed("header-hex-box-color-hover");
  }
}

.c-button--hex {
  border-radius: 0;
  width: $cg-button-hex-width;
  height: calc($cg-button-hex-width * 1.1547);
  border-color: green;
  position: relative;
  display: inline-block;
  border-width: 0;
  font-size: $cg-body-font-size;
  font-weight: 400;
  -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  cursor: pointer;
  appearance: none;
  margin: 0;
  @include themify() {
    background-color: themed("header-hex-box-background-color");
  }
}

.c-button--hex-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include themify() {
    color: themed("header-hex-box-text-color");
  }
  text-align: center;
  padding-top: 10px; // calc((var(--cg-button-hex-width) / 2) - (var(--cg-body-font-size) / 2) - 1px)
  pointer-events: none;
}

.c-header__wrapper > div,
.c-header__wrapper > a,
.c-header__wrapper > img {
  padding-right: 24px;
}

.c-button--hex-dropdown {
  position: relative;
  display: inline-block;

  input[type="checkbox"] {
    position: absolute;
  }

  ul {
    position: absolute;
    z-index: 999;
    display: block;
    left: -200vw;
    top: 50px;
    margin: 0 0 0 -114px;
    @include themify() {
      background-color: themed("header-dropdown-menu-background-color");
    }
    padding: 0 0 5px 0;
    list-style: none;
    width: 170px;
    border-radius: 0 0 4px 4px;
    border: 1px solid;
    @include themify() {
      border-color: transparent themed("header-dropdown-menu-border-color") themed("header-dropdown-menu-border-color")
        themed("header-dropdown-menu-border-color");
    }
  }

  input[type="checkbox"]:checked ~ ul {
    left: 0;
  }

  .menu-item {
    text-align: right;
    padding: 6px 12px;
    margin: 0 11px 0 8px;
    @include themify() {
      color: themed("header-menu-item-text-color");
    }
    line-height: 16px;

    &:hover {
      @include themify() {
        color: themed("header-menu-item-text-color-active");
      }

      cursor: pointer;
    }
  }
}

.c-sub-header {
  display: flex;
  height: 34px;
  @include themify() {
    border-bottom: 1px solid themed("sub-header-border-color");
  }
  margin: 0 -55px;
  align-items: center;
}

.c-sub-header__dropdown {
  display: flex;
  .c-button__dropdown {
    padding: 0 23px 0 6px;
    background: none;
    border: none;
    cursor: pointer;
  }
}
