@use "ddd-styles/variables";

/* Global styles for mat-tooltip */
.mat-mdc-tooltip-panel {
  .mat-mdc-tooltip {
    overflow: visible;
    border-radius: 4px;
    &:after {
      content: "";
      background: variables.$cg-dark-grey-6;
      position: absolute;
      width: 6px;
      height: 6px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent transparent variables.$cg-dark-grey-5 variables.$cg-dark-grey-5;
      z-index: -1;
    }
    .mdc-tooltip__surface {
      background: variables.$cg-dark-grey-6;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      max-width: 300px;
    }
  }
}
/* mat-tooltip arrow positions */
.mat-mdc-tooltip-panel-above {
  .mat-mdc-tooltip {
    &:after {
      top: 100%;
      left: 50%;
      margin-top: -3px;
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}
.mat-mdc-tooltip-panel-below {
  .mat-mdc-tooltip {
    &:after {
      top: 0;
      left: 50%;
      margin-top: -3px;
      transform: translateX(-50%) rotate(135deg);
    }
  }
}
.mat-mdc-tooltip-panel-left {
  .mat-mdc-tooltip {
    &:after {
      top: 50%;
      right: 0;
      margin-right: -3px;
      transform: translateY(-50%) rotate(-135deg);
    }
  }
}
.mat-mdc-tooltip-panel-right {
  .mat-mdc-tooltip {
    &:after {
      top: 50%;
      right: 100%;
      margin-right: -3px;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
