@use "../../variables";

.cg-table-custom {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 4px;
  overflow: hidden;
  td,
  th {
    padding: 10px 10px 10px 15px;
    border: none;
    font-size: 14px;
    line-height: 20px;
  }
  th {
    border-top: 1px solid variables.$cg-mid-grey-3;
    border-bottom: 1px solid variables.$cg-mid-grey-3;
    text-align: left;
    color: variables.$cg-mid-grey-7;
    font-weight: 500;
    position: relative;
    &:last-child {
      border-top-right-radius: 4px;
      border-right: 1px solid variables.$cg-mid-grey-3;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-left: 1px solid variables.$cg-mid-grey-3;
    }
    &:not(:first-child) {
      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 1px;
        background: variables.$cg-mid-grey-3;
        left: -7px;
        top: 20px;
        position: absolute;
        transform: rotate(90deg);
      }
    }
  }
  tr {
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
    .mat-mdc-header-row {
      height: auto;
    }
  }
  tbody {
    td {
      border-bottom: 1px solid variables.$cg-light-grey-6;
      color: variables.$cg-dark-grey-6;
      &:first-child {
        border-left: 1px solid variables.$cg-mid-grey-3;
      }
      &:last-child {
        border-right: 1px solid variables.$cg-mid-grey-3;
      }
    }
    tr {
      &:nth-child(odd) {
        background: variables.$cg-light-grey-1;
      }
      &:last-child {
        td {
          border-bottom: 1px solid variables.$cg-mid-grey-3;
        }
      }

      &.mdc-data-table__row:last-child {
        .mat-mdc-cell {
          border-bottom: 1px solid variables.$cg-mid-grey-3;
        }
      }
    }
  }

  // mat-table styles
  tr {
    &.mat-mdc-header-row {
      height: auto;
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    padding: 10px 10px 10px 15px;
    &.mat-column-actions,
    .mat-column-Actions {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 15px;
  }

  .mat-column-actions,
  .mat-column-Actions {
    width: 150px;
  }

  .select-users-block {
    margin: 20px 0 20px 0;
  }
}

.cg-table-custom-reduced {
  thead {
    th {
      font-size: 13px;
      line-height: 18px;
      padding: 10px;
    }
  }
  tbody {
    td {
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      padding: 10px;
    }
  }
}
