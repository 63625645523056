@use "ddd-styles/variables";

/* Global styles for toasts(alerts) */
.toast-container {
  .cg-toastr {
    padding: 12px 12px 12px 55px;
    width: 465px;
    border-radius: 4px;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    &::before {
      font: 24px tabler-icons;
      position: absolute;
      left: 17px;
      top: 50%;
      line-height: 24px;
      margin-top: -12px;
    }
    &.cg-success-toastr {
      background-color: variables.$cg-green-1;
      background-image: none;
      .toast-title {
        color: variables.$cg-green-8;
      }
      &::before {
        color: variables.$cg-green-8;
        content: "\ea67"; // circle-check
      }
    }
    &.cg-warning-toastr {
      background-color: variables.$cg-orange-1;
      background-image: none;
      .toast-title {
        color: variables.$cg-orange-8;
      }
      &::before {
        color: variables.$cg-orange-8;
        content: "\ea05"; // alert-circle
      }
    }
    &.cg-error-toastr {
      background-color: variables.$cg-red-1;
      background-image: none;
      .toast-title {
        color: variables.$cg-red-8;
      }
      &::before {
        color: variables.$cg-red-8;
        content: "\ea06"; // alert-triangle
      }
    }
    &.cg-info-toastr {
      background-color: variables.$cg-purple-1;
      background-image: none;
      .toast-title {
        color: variables.$cg-purple-8;
      }
      &::before {
        color: variables.$cg-purple-8;
        content: "\eac5"; // info-circle
      }
    }
    .toast-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: variables.$cg-dark-grey-6;
    }
    .toast-message {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: variables.$cg-dark-grey-6;
    }
  }
}
