@import "variables";
@import "mixins";

@import "./carne.scss";
@import "./layout/index";
@import "./component/index";
@import "./old-design/index";

textarea {
  font-family: Haffer;
}

::-webkit-input-placeholder {
  font-family: Haffer;
}
:-moz-placeholder {
  font-family: Haffer;
}
::-moz-placeholder {
  font-family: Haffer;
}
:-ms-input-placeholder {
  font-family: Haffer;
}
