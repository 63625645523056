@import "../../variables";
@import "../../breakpoints";

%overflow-visible {
  .mat-mdc-dialog-container {
    overflow: visible;
  }
}

.cg-dialog-backdrop {
  background-color: $dialogBackdropColor;
}

.cg-dialog {
  max-width: calc(100vh - 60px);
  border-radius: $dialogBorderRadius;

  @each $key, $sizeMap in $dialogSizeMap {
    &.#{$key} {
      @each $sizeKey, $sizeVal in $sizeMap {
        @include setBreakpoint($sizeKey) {
          width: $sizeVal;
        }
      }
    }
  }

  & > .mat-mdc-dialog-container {
    padding: 0;
    box-shadow: none;
  }

  &.overflow-visible {
    @extend %overflow-visible;
  }

  .content {
    padding: 20px 28px;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: $cg-dark-grey-6;
      margin-bottom: 10px;
    }
  }

  .message {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $cg-dark-grey-6;
    margin: 10px 0;
  }

  .sub-message {
    margin-bottom: 5px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;
  }

  .custom-form {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.ddd-dialog__overflow-visible {
  @extend %overflow-visible;
}
