@import "ddd-styles/mixins";
@import "ddd-styles/variables";

.ddd-button {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Haffer, sans-serif;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  text-decoration: none;
  align-items: center;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  & .tabler-icon {
    font-size: 18px;
  }

  @include themify() {
    background-color: themed("white-base");
    border: 1px solid themed("button-border");
    color: themed("button-color");
    & .tabler-icon {
      color: themed("button-icon-color");
    }

    &:hover {
      background-color: themed("button-background-hover-color");
    }

    // ** ENABLE FOR ACCESSIBILITY **
    // &:focus {
    //   outline: themed("button-background-hover-color") solid 2px;
    //   border-color: themed("button-background-hover-color");
    // }

    &:disabled {
      color: themed("button-disabled-font-color");
      & .tabler-icon {
        color: themed("button-disabled-font-color");
      }
      cursor: auto;
      &:hover {
        background-color: themed("white-base");
      }
    }
  }

  &:active {
    &:not(:disabled) {
      padding: 9px 16px 7px 16px;

      &.increased-indents {
        padding: 9px 30px 7px 30px;
      }

      &.ddd-text-icon-button {
        padding: 9px 16px 7px 12px;
      }

      &.ddd-icon-button {
        padding: 0;
      }
    }
  }

  &.tile {
    border-radius: 0;
  }

  &.min-width {
    min-width: 170px;
  }

  &.increased-indents {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.ddd-text-icon-button {
    padding: 8px 16px 8px 12px;
  }

  @include themify() {
    &.ddd-icon-button {
      padding: 0;
      border: 1px solid themed("button-border");
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: themed("button-background-hover-color");
      }
      &--raised {
        .tabler-icon {
          color: themed("primary");
        }
      }
      &--secondary {
        .tabler-icon {
          color: themed("button-icon-color");
        }
      }
      &--large {
        width: 32px;
        height: 32px;
      }
      &--small {
        width: 24px;
        height: 24px;

        .tabler-icon {
          font-size: 16px;
        }
      }
      &:disabled {
        color: themed("button-disabled-font-color");
        border: 1px solid themed("button-disabled-border");
        cursor: auto;
        &:hover {
          background-color: themed("white-base");
        }
        .tabler-icon {
          color: themed("button-disabled-font-color");
        }
      }
    }
  }

  @include themify() {
    &.raised {
      background: themed("primary");
      border-color: themed("primary");
      color: themed("white-base");

      & .tabler-icon {
        color: themed("white-base");
      }

      &:hover {
        background: themed("primary-light");
        border-color: themed("primary-light");
      }

      // ** ENABLE FOR ACCESSIBILITY **
      // &:focus {
      //   outline: themed("button-focus-raised-outline") solid 2px;
      //   border-color: themed("button-focus-raised-outline");
      // }

      &[disabled],
      &.disabled {
        cursor: auto;
        color: themed("primary-light");
        background: themed("button-disabled-raised-color");
        border-color: themed("button-disabled-raised-color");
        & .tabler-icon {
          color: themed("primary-light");
        }
        &:hover {
          background-color: themed("button-disabled-raised-color");
        }
      }
    }
  }

  @include themify() {
    &.accent {
      background-color: themed("white-base");
      border: 1px solid themed("button-accent-border");
      color: themed("button-accent-color");
      & .tabler-icon {
        color: themed("button-accent-color");
      }

      &:hover {
        background-color: themed("button-accent-background-hover-color");
      }

      // ** ENABLE FOR ACCESSIBILITY **
      // &:focus {
      //   outline: themed("button-background-hover-color") solid 2px;
      //   border-color: themed("button-background-hover-color");
      // }

      &:disabled {
        color: themed("button-accent-disabled-font-color");
        & .tabler-icon {
          color: themed("button-accent-disabled-font-color");
        }
        cursor: auto;
        &:hover {
          background-color: themed("white-base");
        }
      }
    }
  }

  &.no-border {
    border: none;
  }
}

.ddd-button-wrapper {
  &--row {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }
  &--column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  &--end {
    justify-content: flex-end;
  }
  &.with-indents {
    margin: 5px 0;
  }
  &--margin-bottom {
    margin-bottom: 20px;
  }
}
