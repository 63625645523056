@import "ddd-styles/variables";

.table-form-field {
  &.mat-mdc-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
      .mdc-text-field__input {
        caret-color: variables.$cg-mid-grey-8;
      }
      .mdc-line-ripple::before {
        border-bottom-color: variables.$cg-mid-grey-8;
      }
      .mdc-line-ripple::after {
        border-bottom-color: variables.$cg-mid-grey-6;
      }
    }
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding: 16px 0 0;
    }
    .mdc-text-field {
      padding: 0;
    }
    .mat-mdc-text-field-wrapper {
      height: 40px;
      flex: auto;
    }
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
    .mat-mdc-select {
      color: variables.$cg-mid-grey-8;
      line-height: 18px;
      font-size: 13px;
      font-weight: 500;
    }
    &.mat-focused {
      .mat-mdc-select-arrow {
        color: variables.$cg-mid-grey-8;
      }
    }
  }
}

.table-form-select {
  &.mat-mdc-select {
    color: variables.$cg-mid-grey-8;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    .mat-mdc-select-value-text {
      color: variables.$cg-dark-grey-7;
    }
  }
}

.table-form-select-panel {
  mat-pseudo-checkbox {
    display: none !important;
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #000;
    z-index: 0;
  }
}

.select-actions-panel {
  &.mat-mdc-select-panel {
    padding: 0;
  }

  .mat-mdc-option {
    color: #000;
  }

  .mat-mdc-option {
    padding: 5px;
    font-size: 11px;
    min-height: auto;
  }

  .mat-mdc-select-panel
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: #000;
    z-index: 0;
  }
}

.date-form-field {
  &.mat-mdc-form-field {
    height: 40px;
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
      .mdc-text-field__input {
        caret-color: variables.$cg-mid-grey-8;
      }
    }
    .mat-mdc-text-field-wrapper {
      flex: auto;
    }
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }
    .mdc-text-field--filled.mdc-text-field--disabled {
      background-color: transparent;
    }
    &.questionnaire-date-field {
      width: 200px;
      font-size: 14px;
      border-bottom: 1px solid $cg-mid-grey-3;
      .mat-datepicker-toggle {
        color: #000;
      }
      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
        .mat-mdc-form-field-infix {
        min-height: 40px;
        padding-top: 7px;
        padding-bottom: 7px;
      }
      .mdc-text-field {
        padding-left: 24px;
      }
      &.send-error {
        border-bottom: 1px solid $cg-red-6;
        .mat-datepicker-toggle {
          color: variables.$cg-red-6;
        }
      }
      .mdc-text-field--filled .mdc-line-ripple::before {
        display: none;
        border-bottom-width: 0;
      }
      .mdc-text-field--filled .mdc-line-ripple::after {
        display: none;
        border-bottom-width: 0;
      }
    }
  }
}

.search-date-menu {
  margin-top: 16px;
  .mat-mdc-menu-content {
    padding: 8px 12px;
    .date-holder {
      mat-form-field {
        display: block;
        width: 100%;
      }
    }
  }
  .mat-mdc-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
      .mdc-text-field__input {
        caret-color: variables.$cg-mid-grey-8;
      }
    }
    .mat-mdc-text-field-wrapper {
      flex: auto;
    }
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
  }
}

.tags-form-field {
  .mat-mdc-text-field-wrapper {
    min-height: 40px;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $cg-mid-grey-3;
    margin: -4px 0;
    font-size: 14px;
    line-height: 22px;
    min-height: 40px;
    &:focus {
      @include themify() {
        border-color: themed("input-selected-border-color");
      }
    }
    &:hover {
      @include themify() {
        border-color: themed("input-selected-border-color");
      }
    }
    .mdc-text-field__input {
      caret-color: variables.$cg-mid-grey-8;
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--filled .mdc-line-ripple::before {
    display: none;
    border-bottom-width: 0;
  }
  .mdc-text-field--filled .mdc-line-ripple::after {
    display: none;
    border-bottom-width: 0;
  }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-bottom: 0;
    padding-top: 0;
  }
  .mdc-evolution-chip-set {
    height: 40px;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    @include themify() {
      background-color: themed("icon-bg-color");
      color: themed("primary");
    }
    height: 34px;
    padding: 2px 0;
    border-radius: 4px;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
  .mdc-evolution-chip-set .mdc-evolution-chip {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    @include themify() {
      color: themed("primary");
    }
  }
  .mat-mdc-form-field-infix {
    min-height: 40px;
  }
  .mat-mdc-chip-focus-overlay {
    @include themify() {
      background-color: themed("icon-bg-color");
    }
  }
  .mdc-text-field {
    padding: 0 10px;
  }
}

div.mat-mdc-autocomplete-panel {
  .mat-mdc-option {
    color: #000;
  }
  .mat-mdc-option {
    min-height: 30px;
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: variables.$cg-dark-grey-6;
  }
}

.tags-search-panel {
  margin-left: -10px;
  margin-top: 10px;
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: variables.$cg-dark-grey-6;
    font-weight: 500;
  }
  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mdc-list-item--selected.mdc-list-item {
    @include themify() {
      background-color: themed("icon-bg-color");
    }
  }
  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: transparent;
  }
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: transparent;
    font-weight: 500;
  }
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    @include themify() {
      background: themed("primary");
    }
  }
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
    width: 7px;
    height: 3px;
  }
}

mat-option.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: variables.$cg-dark-grey-6;
  font-weight: 500;
}

.mat-datepicker-toggle {
  .mdc-icon-button__ripple {
    display: none;
  }
  .mat-ripple {
    display: none;
  }
}

.mat-mdc-paginator {
  margin-top: 5px;
}
