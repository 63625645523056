@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

.dx-widget {
  font-family: Haffer;
}

.companny-link {
  text-decoration: underline;
  font-family: Haffer;
  font-weight: 400;
  color: $cg-mid-grey-8;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-header-row > td,
.dx-datagrid-filter-row > td {
  @include themify() {
    background-color: themed("grid-alt-row-color");
    color: themed("grid-heading-font-color");
  }
}

.dx-checkbox-checked.dx-state-hover .dx-checkbox-icon {
  @include themify() {
    border: 2px solid themed("primary-light");
    background-color: themed("primary-light");
  }
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon {
  @include themify() {
    border: 2px solid themed("primary-light");
  }
}

.dx-checkbox-indeterminate .dx-checkbox-icon {
  @include themify() {
    border: 2px solid themed("primary");
    background-color: themed("primary");
  }
}

.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  width: 10px;
  top: 14px;
  left: 10px;
  height: 2px;
  background-color: $cg-light-grey-1;
}

.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-checkbox-icon {
  @include themify() {
    border-color: themed("primary-light");
  }
}

.dx-treelist-checkbox-size.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  width: 8px;
  top: 9px;
  left: 6px;
  height: 2px;
  background-color: $cg-light-grey-1;
}

.dx-datagrid-headers + .dx-datagrid-rowsview,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td,
.dx-datagrid .dx-column-lines > td,
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none {
  @include themify() {
    border-color: themed("grid-border-color");
  }
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  @include themify() {
    background-color: themed("grid-global-filter-color");
  }
}

.dx-datagrid .dx-row > td {
  padding: 14px;
}

.dx-page-size {
  font-family: Haffer;
}

.dx-datagrid-headers {
  font-family: Haffer;
  font-weight: 500;
}

.dx-checkbox-checked .dx-checkbox-icon,
.dx-datagrid-filter-panel .dx-icon-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid .dx-header-filter {
  @include themify() {
    color: themed("grid-global-filter-color");
  }
}

.dx-checkbox-checked .dx-checkbox-icon {
  @include themify() {
    border: 2px solid themed("grid-global-filter-color");
    background-color: themed("grid-global-filter-color");
    color: white;
  }
}

.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > td {
  background-color: white;
  color: $cg-dark-grey-6;
}

.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
  border: none;
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  @include themify() {
    border: 2px solid themed("grid-global-filter-color");
  }
}

.dx-pages > .dx-info {
  @include themify() {
    opacity: 1;
  }
}

.dx-treelist-focus-overlay {
  @include themify() {
    border: none;
  }
}

.dx-widget,
.dx-datagrid,
.companny-link,
.dx-pager .dx-pages .dx-info,
.dx-pager .dx-pages .dx-next-button,
.dx-pager .dx-pages .dx-prev-button,
.dx-datagrid .dx-header-filter-empty,
.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
.dx-button-mode-contained .dx-icon,
.dx-dropdowneditor-icon {
  @include themify() {
    color: themed("grid-font-color");
  }
}

.dx-datagrid {
  .dx-row-alt > td,
  .dx-row-alt > tr > td {
    @include themify() {
      background-color: themed("grid-alt-row-color");
    }
  }
}

.dx-datagrid .dx-header-filter {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dx-datagrid .dx-icon-filter-operation-default::before,
.dx-datagrid-container .dx-icon-filter-operation-default::before {
  font: 16px tabler-icons;
  content: "\eb1c"; // Search icon
}

.dx-datebox-calendar .dx-dropdowneditor-icon::before {
  font: 16px tabler-icons;
  content: "\ea52"; // Calendar icon
}

.dx-datagrid .dx-header-filter:before {
  font: 20px tabler-icons;
  content: "\eaa5"; // Filter icon
}

.dx-button {
  border-radius: 28px;
}

.dx-datagrid-header-panel .dx-item-content.dx-toolbar-item-content > .dx-button,
.filter-toolbar-item > div > .dx-button {
  padding: 5px 7px 7px 5px;
}

.dx-toolbar-text-auto-hide .dx-button .dx-button-content,
.dx-button-has-icon .dx-button-content {
  padding: 0;
}

.dx-icon-filter:before {
  font: 20px tabler-icons;
  content: "\eaa5"; // Filter icon (Toolbar)
}

.dx-icon-xlsxfile::before {
  font: 20px tabler-icons;
  content: "\f03e"; // Spreadsheet icon (Toolbar)
}

.dx-icon-columnchooser::before {
  font: 20px tabler-icons;
  content: "\f6d6"; // Column icon (Toolbar)
}

.dx-switch .dx-switch-wrapper {
  display: flex;
}

.dx-popup-content {
  padding: 16px;
}

.dx-treelist-headers .dx-treelist-table .dx-row > td {
  border: none;
}

.dx-treelist-borders > .dx-treelist-headers {
  border: none;
}

.dx-treelist-borders > .dx-treelist-rowsview {
  border: none;
}

.dx-treelist-select-all .dx-checkbox {
  left: 0;
}

.dx-treelist .dx-row > td {
  padding-left: 0;
}

.dx-treelist-headers .dx-header-row > .dx-treelist-select-all {
  padding-left: 23px;
}

.dx-treelist-search-text {
  @include themify() {
    background-color: themed("grid-global-filter-color");
  }
}

.dx-treelist-rowsview tr:not(.dx-row-focused).dx-selection .dx-treelist-empty-space {
  color: $cg-mid-grey-6;
}

.dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space {
  color: $cg-mid-grey-6;
}

.dx-checkbox-container {
  border-radius: 4px;
}

.dx-checkbox-icon {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  border: 2px solid;
}

.dx-treelist-checkbox-size .dx-checkbox-icon {
  height: 18px;
  width: 18px;
}

.dx-treelist-text-content {
  line-height: 20px;
  padding-left: 4px;
}

.dx-button-mode-contained.dx-buttongroup-last-item.dx-button,
.dx-button-mode-contained.dx-buttongroup-last-item.dx-state-hover .dx-button-content,
.dx-button-mode-outlined.dx-buttongroup-last-item.dx-button,
.dx-button-mode-outlined.dx-buttongroup-last-item.dx-state-hover .dx-button-content {
  border-radius: 4px;
}

.dx-button-mode-contained.dx-buttongroup-first-item.dx-button,
.dx-button-mode-contained.dx-buttongroup-first-item.dx-state-hover .dx-button-content,
.dx-button-mode-outlined.dx-buttongroup-first-item.dx-button,
.dx-button-mode-outlined.dx-buttongroup-first-item.dx-state-hover .dx-button-content {
  border-radius: 4px;
}

.dx-icon-edit {
  font: 18px tabler-icons;
}

.dx-icon-edit:before {
  content: "\eb04";
}

.dx-icon-trash {
  font: 18px tabler-icons;
  color: $cg-red-7;
}

.dx-icon-trash:before {
  content: "\eb41";
  color: $cg-red-7;
}

.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text
  .dx-filterbuilder-item-value-text,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-group-operation,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-item-field,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-item-operation {
  color: $cg-mid-grey-7;
  background-color: $cg-light-grey-3;
  padding: 10px 11px 10px 13px;
  border: 1px solid $cg-mid-grey-3;
}

.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-group-operation:after,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-item-field:after,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-item-operation:after {
  font: 16px tabler-icons;
  content: "\ea5f";
  margin-left: 19px;
}

.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-text.dx-filterbuilder-item-field.dx-state-active,
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-text.dx-filterbuilder-item-field:focus {
  color: $cg-mid-grey-7;
  background-color: $cg-light-grey-3;
}

.dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
  color: $cg-mid-grey-7;
  background-color: $cg-light-grey-5;
}

.dx-treeview-item.dx-state-hover {
  color: $cg-mid-grey-7;
  background-color: $cg-light-grey-3;
}

.dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  color: $cg-mid-grey-7;
  background-color: $cg-mid-grey-3;
}

.dx-treeview-item-without-checkbox.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
  background-color: $cg-light-grey-6;
}

.dx-treeview-item-with-checkbox.dx-state-focused > .dx-checkbox .dx-checkbox-icon {
  @include themify() {
    border: 2px solid themed("grid-global-filter-color");
  }
}

.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item {
  margin-bottom: 10px;
}

.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-action.dx-filterbuilder-action-icon {
  padding: 6px;
  border: 1px solid $cg-mid-grey-3;
  border-radius: 16px;
  color: $cg-mid-grey-7;
}

.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-action.dx-filterbuilder-action-icon:hover {
  background-color: $cg-light-grey-3;
}

.dx-filterbuilder .dx-filterbuilder-action-icon,
.dx-filterbuilder .dx-filterbuilder-text {
  margin-left: 0;
  margin-right: 16px;
}

.dx-icon-remove::before {
  font: 16px tabler-icons;
  content: "\eb41";
  color: $cg-red-6;
}

.dx-list .dx-empty-message,
.dx-list-item {
  color: $cg-mid-grey-7;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: $cg-light-grey-5;
  color: $cg-mid-grey-7;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
  background-color: $cg-light-grey-3;
  color: $cg-mid-grey-7;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
  background-color: $cg-light-grey-5;
  color: $cg-mid-grey-7;
}

.dx-dropdownbutton-popup-wrapper {
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: variables.$cg-white;
    color: variables.$cg-dark-grey-6;
    font-weight: 500;
    &.dx-state-focused,
    &.dx-state-hover,
    &.dx-state-active {
      background-color: variables.$cg-white;
    }
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item {
    color: variables.$cg-mid-grey-6;
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: variables.$cg-white;
    color: variables.$cg-dark-grey-6;
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    background-color: variables.$cg-white;
    color: variables.$cg-dark-grey-6;
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    background-color: variables.$cg-white;
    color: variables.$cg-dark-grey-6;
  }
}

.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-hover .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-hover .dx-radiobutton-icon::before {
  @include themify() {
    border-color: themed("primary");
  }
}
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-focused .dx-radiobutton-icon::before {
  @include themify() {
    border: 1px solid themed("primary");
  }
}

.dx-loadindicator-icon .dx-loadindicator-segment {
  @include themify() {
    background: themed("primary");
  }
}

.dx-template-wrapper > progress[value] {
  &::-webkit-progress-value {
    @include themify() {
      background-color: themed("primary");
    }
  }

  &::-moz-progress-bar {
    @include themify() {
      background-color: themed("primary");
    }
  }

  @include themify() {
    color: themed("primary");
  }
}

.dx-field-label {
  font-family: "Haffer";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $cg-dark-grey-6;
  text-align: right;
}

.dx-texteditor.dx-editor-outlined {
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: $inputBorderRadius;
  border-color: $cg-mid-grey-3;
}

.dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-texteditor.dx-state-active.dx-editor-outlined {
  @include themify() {
    border-color: themed("input-selected-border-color");
  }
}

.dx-texteditor.dx-state-hover {
  @include themify() {
    border-color: themed("input-selected-border-color");
  }
}

.dx-scrollable-scroll-content {
  border-radius: 25px;
  @include themify() {
    background-color: themed("primary");
  }
}

.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
  @include themify() {
    background-color: themed("primary");
  }
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
}

.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
}

.dx-show-clear-button .dx-icon-clear {
  font-size: 16px;
  line-height: 16px;
}

.dx-icon-clear::before {
  color: $cg-mid-grey-6;
  font: 16px tabler-icons;
  content: "\eb55"; //icon-x;
}

.dx-datagrid-focus-overlay {
  @include themify() {
    border: 2px solid themed("primary");
  }
}

.dx-menu-item.dx-state-hover {
  @include themify() {
    background-color: themed("dx-menu-item-hover");
  }
}

.dx-menu-item.dx-menu-item-selected {
  @include themify() {
    background-color: themed("dx-menu-item-selected");
  }
}

.dx-menu-item.dx-menu-item-selected.dx-state-hover {
  @include themify() {
    background-color: themed("dx-menu-item-hover");
  }
}

.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-texteditor.dx-state-focused::after {
  @include themify() {
    border: 2px solid themed("primary");
  }
}

.dx-calendar-navigator .dx-button.dx-state-hover:not(.dx-calendar-disabled-navigator-link) {
  @include themify() {
    border-color: themed("primary");
  }
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
  @include themify() {
    color: themed("primary");
  }
}

.dx-calendar-cell.dx-state-hover:not(.dx-calendar-empty-cell) {
  span {
    @include themify() {
      box-shadow: inset 0 -1px 0 1000px rgba(91, 91, 91, 0.12);
      background-color: themed("primary");
      color: #fff;
    }
  }
}

.dx-calendar-cell.dx-state-hover.dx-calendar-empty-cell span,
.dx-calendar-cell.dx-state-active.dx-calendar-empty-cell span {
  color: $cg-mid-grey-2;
}

.dx-calendar-cell.dx-calendar-empty-cell {
  color: $cg-mid-grey-2;
}

.dx-calendar-cell.dx-state-active {
  span {
    @include themify() {
      background-color: themed("primary");
    }
  }
}
.dx-calendar-cell.dx-calendar-empty-cell.dx-state-active span,
.dx-calendar-cell.dx-calendar-empty-cell.dx-state-hover span {
  background-color: transparent;
}

.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
  span {
    @include themify() {
      box-shadow:
        inset 0 0 0 1px #bdc3c7,
        inset 0 0 0 1000px themed("primary");
    }
  }
}

.dx-calendar-cell.dx-calendar-selected-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
  span {
    @include themify() {
      box-shadow: inset 0 0 0 1000px themed("primary");
      background-color: themed("primary");
    }
  }
}

.dx-calendar-cell.dx-state-active:not(.dx-calendar-empty-cell):not(.dx-calendar-selected-date) {
  @include themify() {
    box-shadow: inset 0 -1px 0 1000px themed("dx-menu-item-selected");
  }
}

.dx-calendar-cell.dx-calendar-today span {
  @include themify() {
    border: 2px solid themed("primary");
  }
}

.dx-calendar-cell.dx-calendar-contoured-date span {
  box-shadow: none;
}

.dx-datagrid .dx-error-row .dx-error-message {
  background-color: $cg-red-6;
}

.dx-datagrid-columns-separator {
  @include themify() {
    background-color: themed("primary");
  }
}

.dx-datagrid-columns-separator-transparent {
  @include themify() {
    background-color: transparent;
  }
}

.dx-datagrid-drag-action {
  @include themify() {
    border: 1px solid themed("primary");
  }
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > tr > td {
  @include themify() {
    background-color: themed("primary");
  }
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
  @include themify() {
    border-bottom: 1px solid themed("primary");
    border-top: 1px solid themed("primary");
  }
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > tr > td {
  @include themify() {
    border-left-color: themed("primary");
    border-right-color: themed("primary");
  }
}

.dx-datagrid-rowsview .dx-row {
  @include themify() {
    border-top: 1px solid themed("grid-border-color");
    border-bottom: 1px solid themed("grid-border-color");
  }
}

.dx-datagrid .dx-row-alt.dx-row:not(.dx-row-removed) {
  @include themify() {
    border-bottom-color: themed("grid-border-color");
  }
}

.dx-datagrid-filter-row .dx-menu-item.dx-state-focused::after {
  @include themify() {
    border: 2px solid themed("primary");
  }
}

.dx-popup-wrapper {
  &.risk-rating-popup {
    > .dx-overlay-content {
      border: 1px solid $cg-mid-grey-3;
      box-shadow: none;
      border-radius: 8px;
    }
  }
}
