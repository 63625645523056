@import "ddd-styles/variables";
@import "ddd-styles/mixins";

/*Global old styles for ng-select */
.ng-select {
  font-size: 14px;

  &.ng-select-multiple {
    .ng-select-container {
      min-height: 40px;
      .ng-value-container {
        .ng-value {
          white-space: normal;

          .ng-value-label {
            display: inline;
          }
          .ng-value-icon {
            display: inline;

            &.left {
              display: inline;
              border-right: none;
            }
          }
        }

        .ng-placeholder {
          top: auto;
        }
      }
    }
    &.ng-select-disabled {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            .ng-value-icon {
              display: none;

              &.left {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: 40px;
      min-height: 40px;

      .ng-value-container {
        .ng-value {
          white-space: normal;
        }
      }
    }
  }

  &.fm-review-answer-highlight-red {
    .ng-select-container {
      background-color: $cg-red-1;
    }
  }

  &.fm-review-answer-highlight-amber {
    .ng-select-container {
      background-color: $cg-orange-1;
    }
  }
}

.ng-dropdown-panel {
  font-size: 14px;
  .ng-dropdown-panel-items {
    .ng-option {
      white-space: normal;
    }
  }
  &.form-custom-select {
    font-size: 13px;
  }
}

.ng-value-label {
  &.fm-review-answer-highlight-red {
    background-color: $cg-red-1;
  }
}

.ng-value-label {
  &.fm-review-answer-highlight-amber {
    background-color: $cg-orange-1;
  }
}

.fm-review-answer-highlight-red {
  background-color: $cg-red-1;
}

.fm-review-answer-highlight-amber {
  background-color: $cg-orange-1;
}

/*Global CG like styles for ng-select */
.ng-select {
  &.ng-select-opened {
    & > .ng-select-container {
      @include themify() {
        border-color: themed("input-selected-border-color");
      }
    }
    &.ng-select-bottom {
      & > .ng-select-container {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &.ng-select-top {
      & > .ng-select-container {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }
  &.ng-select-focused:not(.ng-select-opened) {
    & > .ng-select-container {
      @include themify() {
        border-color: themed("input-selected-border-color");
      }
      box-shadow: none;
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          color: $cg-dark-grey-6;
          background-color: $cg-light-grey-6;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          .ng-value-icon {
            font-size: 0;
            line-height: 0;
            padding: 5px 5px 5px 0;
            color: $cg-light-grey-6;
            &:hover {
              background-color: transparent;
              &:before {
                color: $cg-mid-grey-8;
              }
            }
            &:before {
              color: $cg-mid-grey-6;
              font: 16px tabler-icons;
              content: "\eb55"; //icon-x;
            }
          }
          .ng-value-label {
            padding: 1px 10px;
          }
        }
      }
    }
  }
  .ng-clear-wrapper {
    &:hover {
      .ng-clear {
        color: $cg-red-6;
      }
    }
  }
}

.ng-dropdown-panel {
  border: 1px solid $cg-mid-grey-3;
  &.ng-select-bottom {
    margin-top: 4px;
    border-radius: 8px;
    border-top-color: $cg-mid-grey-3;
  }
  &.ng-select-top {
    margin-bottom: 4px;
    border-radius: 8px;
    border-bottom-color: $cg-mid-grey-3;
  }
  .ng-dropdown-panel-items {
    border-radius: 8px;
    min-height: 43px;
    .ng-option {
      margin: 6px 5px;
      border-radius: 4px;
      color: $cg-mid-grey-8;
      &.ng-option-marked {
        background-color: $cg-light-grey-2;
        &.ng-option-selected {
          color: $cg-dark-grey-6;
          background-color: $cg-light-grey-3;
          .ng-option-label {
            font-weight: 500;
          }
        }
      }
      &.ng-option-selected {
        color: $cg-dark-grey-6;
        background-color: $cg-light-grey-3;
        .ng-option-label {
          font-weight: 500;
        }
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
    }
  }
}

.ng-select > .ng-select-container .ng-arrow-wrapper .ng-arrow {
  top: -4px;
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 1px;
  border-top: 1px solid $cg-mid-grey-6;
  border-right: 1px solid $cg-mid-grey-6;
  width: 6px;
  height: 6px;
  display: inline-block;
  transform: rotate(135deg);
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -1px;
  border-width: 0;
  border-top: 1px solid $cg-mid-grey-6;
  border-right: 1px solid $cg-mid-grey-6;
  transform: rotate(-45deg);
  width: 6px;
  height: 6px;
}

.setup-form .ng-select.ng-select-opened > .ng-select-container {
  background-color: unset;
}
