.ddd-guide {
  .guide__list {
    margin: 0;
    padding: 0;

    .guide__list-element {
      position: relative;
      list-style-type: none;
      line-height: 22px;
      font-size: 14px;
      margin-left: 10px;

      &::before {
        margin-left: -10px;
        position: absolute;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.57);
      }

      &:not(:first-child) {
        padding-top: 8px;
      }
    }
  }
}
