@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

.ddd-progress {
  &.ddd-progress__line {
    @include themify() {
      .mdc-linear-progress__buffer-bar {
        background-color: themed("primary-light-2");
      }
      .mdc-linear-progress__bar-inner {
        border-color: themed("primary");
      }
    }
  }
}
