* {
  &:active,
  :focus {
    outline: none !important;
  }
}

label {
  margin-bottom: 0;
}

html,
body {
  width: 100%;
}

html {
  height: 100%;
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

// hide a scrollbar on textareas on IE
textarea {
  overflow: auto;
}
