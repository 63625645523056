@import "../../variables";
@import "../../mixins";
@import "../../functions";
// mdc-radio
.ddd-radio-button {
  &.mat-mdc-radio-button {
    @include themify() {
      &.mat-mdc-radio-checked {
        .mdc-radio__background::before {
          background-color: #fff;
        }
        .mat-radio-ripple .mat-ripple-element {
          background-color: themed("icon-bg-color");
        }
      }
      &.low-contrast {
        .mdc-label {
          font-family: Haffer, sans-serif;
        }
      }
      .mdc-radio {
        .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
          border-color: themed("primary");
          background-color: themed("primary");
        }
        .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
          border-color: $cg-mid-grey-3;
        }
        .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
          border-color: #fff;
        }
        .mdc-radio__background::before {
          background-color: themed("icon-bg-color");
        }
        // disabled state
        [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
        .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
          border-color: $cg-mid-grey-5;
        }
        [aria-disabled="true"]
          .mdc-radio__native-control:not(:checked)
          + .mdc-radio__background
          .mdc-radio__outer-circle,
        .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
          border-color: $cg-mid-grey-5;
        }
        [aria-disabled="true"] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
        .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
          border-color: $cg-mid-grey-5;
        }
      }
      .mdc-label {
        color: $cg-dark-grey-6;
        font-family: Haffer, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 13px;
        font-weight: 500;
      }
      // disabled state
      .mdc-radio--disabled + label {
        color: $cg-mid-grey-5;
      }
    }
  }
}
