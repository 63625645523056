@import "../../variables";
@import "../../mixins";

.ddd-breadcrumbs {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: inline;
    color: $breadcrumbsColor;
    font-size: $breadcrumbsSize;
    line-height: $breadcrumbsLineHeight;
    font-weight: normal;

    a {
      text-decoration: none;
      color: $breadcrumbsColor;

      &.active-link {
        cursor: pointer;
        @include onHover() {
          text-decoration: underline;
        }
        @include themify() {
          color: themed("old-design-main-color");
        }
      }
    }
  }

  li + li:before {
    padding: 8px 14px;
    color: $breadcrumbsColor;
    content: "\003e";
  }
}

.ddd-breadcrumbs-box {
  width: 100%;
  margin-top: -27px;
}
