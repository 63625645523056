@import "variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

.c-color--accent {
  @include themify() {
    color: themed("primary");
  }
}

.c-color--mid-grey-7 {
  color: $cg-mid-grey-7;
}

.c-color--red-7 {
  color: $cg-red-7 !important;
}

.c-font--caption {
  font-size: $cg-font-size-caption;
}

.c-font--bold {
  font-weight: 500;
}

.c-font--heavy-bold {
  font-weight: 700;
}

.c-right-pad-10 {
  padding-right: 10px;
}

:root {
  --select-focus: blue;
}

.c-select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0px 12px 0 24px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  &::-ms-expand {
    display: none;
  }
  outline: none;
  height: 100%;
  width: 100%;
  white-space: pre-wrap;
}

.c-select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;
  max-width: 30ch;

  border: 1px solid $cg-mid-grey-3;
  border-radius: 0.25em;
  padding: 0 9px 0 0;
  margin-right: 4px;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 18px;
  white-space: pre-wrap;

  &::after {
    content: "";
    justify-self: end;
    width: 10px;
    height: 5px;
    background-color: $cg-mid-grey-6;
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%, 80% 0%, 50% 58%, 20% 0%);
  }
}

.c-select select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid $cg-purple-4;
  border-radius: inherit;
}

.c-checkbox {
  appearance: none;
  background-color: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  @include themify() {
    border: 2px solid themed("primary-light");
  }
}

.c-checkbox:checked {
  @include themify() {
    background-color: themed("primary-light");
  }
}

.c-checkbox::before {
  content: "";
  width: 10px;
  height: 10px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;
  background-color: CanvasText;
}

.c-checkbox:checked::before {
  transform: scale(1);
}

.c-checkbox:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.c-checkbox:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
