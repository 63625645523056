.dx-popover-wrapper {
  .dx-popover-arrow {
    display: none;
    &:after {
      width: 0;
      height: 0;
    }
  }
}

.dx-tooltip-wrapper {
  .dx-overlay-content {
    border: none;
    background-color: #7c7c7d;
    color: #ffffff;
    .dx-popup-content {
      color: #ffffff;
      font-family: OpenSans, sans-serif;
      padding: 7px 10px 7px 7px;
      font-weight: 200;
      background-color: #7c7c7d;
      border-radius: 6px;
    }
    &.dx-popup-normal {
      &.dx-resizable {
        transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
      }
    }
  }
}
