@import "./mixin";
@import "../../variables";
@import "../../mixins";
@import "../../functions";

.ddd-input {
  min-height: 40px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: $inputBorderRadius;
  font-size: $inputFontSize;
  line-height: $inputLineHeight;
  padding: 9px 10px;
  font-family: Haffer, sans-serif;
  border-color: $cg-mid-grey-3;
  background-color: #fff;
  color: $inputColor;

  @include placeholder {
    color: $inputPlaceholderColor;
    font-family: Haffer, sans-serif;
    font-size: 14px;
    // padding: 9px 10px;
  }

  &.w-100 {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.6;
  }

  &.valid-error {
    border-color: $cg-red-6;
  }

  @include onHover {
    @include themify() {
      border-color: themed("input-selected-border-color");
    }
  }
}

textarea.ddd-input {
  resize: vertical;
  min-height: 40px;
  font-family: Haffer, sans-serif;
}
