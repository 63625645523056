@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";
@import "ddd-styles/component/button/button";

.ddd-pagination {
  .list {
    margin: 0;
    padding: 8px 0;
    width: 100%;
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;

    .list__item {
      list-style: none;
      display: inline-block;
      margin: 0 5px;
      padding: 0;
      vertical-align: middle;

      .ddd-button {
        min-width: inherit;
        width: 40px;
        border-color: #7c7c7d;
      }
    }
  }
}
