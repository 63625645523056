@import "./breakpoints";

/* Carne color palette */
$cg-dark-grey-2: #d2d3d5;
$cg-dark-grey-3: #a5a8ab;
$cg-dark-grey-5: #4a5157;
$cg-dark-grey-6: #1d252d;
$cg-dark-grey-7: #171e24;

$cg-purple-1: #f4edff;
$cg-purple-3: #d1b5ff;
$cg-purple-4: #ba90ff;
$cg-purple-6: #8c46ff;
$cg-purple-7: #7038cc;
$cg-purple-8: #542a99;

$cg-mid-grey-2: #e6e8eb;
$cg-mid-grey-3: #ccd1d6;
$cg-mid-grey-4: #b3bbc2;
$cg-mid-grey-5: #99a4ad;
$cg-mid-grey-6: #808d99;
$cg-mid-grey-7: #66717a;
$cg-mid-grey-8: #4d555c;
$cg-mid-grey-9: #33383d;

$cg-light-grey-1: #fdfdfe;
$cg-light-grey-2: #fbfbfc;
$cg-light-grey-3: #f7f8f9;
$cg-light-grey-5: #eff1f3;
$cg-light-grey-6: #ebedf0;
$cg-light-grey-7: #bcbec0;
$cg-light-grey-8: #8d8e90;

$cg-green-1: #edfaee;
$cg-green-6: #46c850;
$cg-green-8: #2a7830;

$cg-orange-1: #fff0e6;
$cg-orange-5: #ff8333;
$cg-orange-8: #993c00;

$cg-red-1: #feeef0;
$cg-red-3: #f9b8c4;
$cg-red-6: #f14d6b;
$cg-red-7: #c13e56;
$cg-red-8: #912e40;
$cg-red-9: #601f2b;

$cg-turquoise-1: #e6f9f8;
$cg-turquoise-3: #99e5e2;
$cg-turquoise-8: #00726e;

/* FC color palette */
$fc-blue-7: #3b6e85;
$fc-blue-6: #4a8aa6; //main color
$fc-blue-3: #b7d0db;
$fc-blue-1: #edf3f6;
$fc-blue-4: #92b9ca;

/* KYD color palette */
$kyd-orange-7: #be6f00;
$kyd-orange-6: #ed8b00; //main color
$kyd-orange-3: #f8d199;
$kyd-orange-1: #fdf3e6;
$kyd-orange-4: #f7c089;

$cg-font-size-caption: 0.8571rem;
$cg-logo-curator: url("/assets/images/main/powered-by-curator.svg");
$cg-logo-simplicity: url("/assets/images/main/unity-simplicity-logo.svg");
$cg-logo: url("/assets/images/main/logo-curator-dd.svg");
$cg-kyd-logo: url("/assets/images/main/kyd-logo.svg");
$cg-fc-logo: url("/assets/images/main/fc-logo.svg");
$cg-auditor-selected: #ffe599;
$cg-auditor-selected-satisfactory: #46a35e;
$cg-auditor-selected-needs-improvement: #ff8333;
$cg-auditor-selected-unsatisfactory: #f14d6b;
$cg-white: #fff;
$cg-mig-grey-custom: #808d99;

$themes: (
  ddd-theme: (
    primary: $cg-purple-6,
    primary-light: $cg-purple-7,
    primary-light-2: rgba(227, 239, 252, 0.3),
    accent: #df5d0d,
    accent-light: #f1963a,
    accent-dark: #f25c00,
    danger: #df0d0d,
    success: #64a71e,
    success-light: #6bc111,
    white-base: $cg-white,
    black-base: #000000,
    grey-base: $cg-light-grey-3,
    grey-base-2: #c8c8c8,
    grey-dark: #7c7c7d,
    grey-dark-2: #424242,
    grey-dark-3: #c1c1c1,
    transparent-state: rgba(0, 0, 0, 0),
    old-design-main-color: $cg-purple-6,
    old-design-secondary-color: #113868,
    old-design-main-color-rgba: rgba(240, 85, 37, 0.1),
    old-design-main-color-nav-menu-rgba: rgba(240, 85, 37, 0.85),
    arrow-guide-closed-icon-color: #bcbcbc,
    display-brand-icon-only: none,
    display-ddd-logo-box: block,
    grid-heading-background-color: $cg-light-grey-6,
    grid-alt-row-color: $cg-light-grey-1,
    grid-heading-font-color: $cg-mid-grey-7,
    grid-font-color: $cg-mid-grey-7,
    grid-border-color: $cg-mid-grey-3,
    grid-global-filter-color: $cg-purple-6,
    footer-background-color: $cg-mid-grey-6,
    footer-logo: $cg-logo-simplicity,
    footer-border-width: 0,
    footer-font-color: $cg-white,
    icon-color: $cg-white,
    icon-border: $cg-light-grey-6,
    button-border: $cg-mid-grey-3,
    button-color: $cg-mid-grey-8,
    button-background-hover-color: $cg-light-grey-6,
    button-icon-color: $cg-mid-grey-6,
    button-disabled-border: $cg-light-grey-6,
    button-disabled-raised-color: $cg-purple-1,
    button-disabled-font-color: $cg-mid-grey-3,
    button-focus-raised-outline: $cg-purple-3,
    icon-bg-color: $cg-purple-1,
    progress-bg-color: $cg-purple-1,
    input-selected-border-color: $cg-purple-4,
    button-icon-light-color: $cg-mid-grey-6,
    button-accent-border: $cg-red-6,
    button-accent-color: $cg-red-6,
    button-accent-background-hover-color: $cg-red-1,
    button-disabled-accent-color: $cg-red-1,
    header-background-color: $cg-dark-grey-6,
    header-dropdown-menu-background-color: $cg-dark-grey-6,
    header-dropdown-menu-border-color: $cg-dark-grey-6,
    header-menu-item-text-color: $cg-mid-grey-5,
    header-menu-item-text-color-active: $cg-light-grey-1,
    header-username-text-color: $cg-white,
    header-company-text-color: $cg-mig-grey-custom,
    header-hex-box-color-hover: $cg-purple-6,
    header-hex-box-background-color: $cg-white,
    header-hex-box-text-color: $cg-purple-6,
    header-border-color: $cg-dark-grey-6,
    sub-header-border-color: $cg-light-grey-6,
    header-logo: $cg-logo,
    dx-menu-item-selected: $cg-purple-3,
    dx-menu-item-hover: $cg-purple-1
  ),
  fcc-theme: (
    primary: $fc-blue-6,
    primary-light: $fc-blue-7,
    primary-light-2: rgba(227, 239, 252, 0.3),
    accent: #df5d0d,
    accent-light: #f1963a,
    accent-dark: #f25c00,
    danger: #df0d0d,
    success: #64a71e,
    success-light: #6bc111,
    white-base: $cg-white,
    black-base: #000000,
    grey-base: $cg-light-grey-3,
    grey-base-2: #c8c8c8,
    grey-dark: #7c7c7d,
    grey-dark-2: #424242,
    grey-dark-3: #c1c1c1,
    transparent-state: transparent,
    old-design-main-color: $fc-blue-6,
    old-design-secondary-color: $fc-blue-6,
    old-design-main-color-rgba: rgba(240, 85, 37, 0.1),
    old-design-main-color-nav-menu-rgba: rgba(74, 138, 166, 0.85),
    arrow-guide-closed-icon-color: $cg-white,
    display-brand-icon-only: block,
    display-ddd-logo-box: block,
    footer-background-color: #eee,
    footer-logo: $cg-logo-curator,
    grid-heading-background-color: $cg-light-grey-6,
    grid-alt-row-color: $cg-light-grey-1,
    grid-heading-font-color: $cg-mid-grey-7,
    grid-font-color: $cg-mid-grey-7,
    grid-border-color: $cg-mid-grey-3,
    grid-global-filter-color: $fc-blue-6,
    footer-border-width: 4px,
    footer-font-color: rgba(0, 0, 0, 0.87),
    icon-color: $cg-white,
    icon-border: $cg-light-grey-6,
    button-border: $cg-mid-grey-3,
    button-color: $cg-mid-grey-8,
    button-background-hover-color: $cg-light-grey-6,
    button-icon-color: $cg-mid-grey-6,
    button-disabled-border: $cg-light-grey-6,
    button-disabled-raised-color: $fc-blue-1,
    button-disabled-font-color: $cg-mid-grey-3,
    button-focus-raised-outline: $fc-blue-3,
    icon-bg-color: $fc-blue-1,
    progress-bg-color: $fc-blue-1,
    input-selected-border-color: $fc-blue-4,
    button-icon-light-color: $cg-mid-grey-6,
    button-accent-border: $cg-red-6,
    button-accent-color: $cg-red-6,
    button-accent-background-hover-color: $cg-red-1,
    button-disabled-accent-color: $cg-red-1,
    header-background-color: $cg-white,
    header-dropdown-menu-background-color: $cg-light-grey-1,
    header-dropdown-menu-border-color: $cg-light-grey-6,
    header-menu-item-text-color: $cg-mid-grey-5,
    header-menu-item-text-color-active: $cg-dark-grey-6,
    header-username-text-color: $cg-dark-grey-6,
    header-company-text-color: $cg-mid-grey-7,
    header-hex-box-color-hover: $fc-blue-6,
    header-hex-box-background-color: $fc-blue-1,
    header-hex-box-text-color: $fc-blue-6,
    header-border-color: $cg-light-grey-6,
    sub-header-border-color: $cg-light-grey-6,
    header-logo: $cg-fc-logo,
    dx-menu-item-selected: $fc-blue-3,
    dx-menu-item-hover: $fc-blue-1
  ),
  kyd-theme: (
    primary: $kyd-orange-6,
    primary-light: #c1782e,
    primary-light-2: rgba(227, 239, 252, 0.3),
    accent: #df5d0d,
    accent-light: #f1963a,
    accent-dark: #f25c00,
    danger: #df0d0d,
    success: #64a71e,
    success-light: #6bc111,
    white-base: $cg-white,
    black-base: #000000,
    grey-base: $cg-light-grey-3,
    grey-base-2: #c8c8c8,
    grey-dark: #7c7c7d,
    grey-dark-2: #424242,
    grey-dark-3: #c1c1c1,
    transparent-state: transparent,
    old-design-main-color: $kyd-orange-6,
    old-design-secondary-color: $kyd-orange-6,
    old-design-main-color-rgba: rgba(240, 85, 37, 0.1),
    old-design-main-color-nav-menu-rgba: rgba(237, 139, 0, 0.85),
    arrow-guide-closed-icon-color: $cg-white,
    display-brand-icon-only: block,
    display-ddd-logo-box: block,
    footer-background-color: #eee,
    footer-logo: $cg-logo-curator,
    grid-heading-background-color: $cg-light-grey-6,
    grid-alt-row-color: $cg-light-grey-1,
    grid-heading-font-color: $cg-mid-grey-7,
    grid-font-color: $cg-mid-grey-7,
    grid-border-color: $cg-mid-grey-3,
    grid-global-filter-color: $kyd-orange-6,
    footer-border-width: 4px,
    footer-font-color: rgba(0, 0, 0, 0.87),
    icon-color: $cg-white,
    icon-border: $cg-light-grey-6,
    button-border: $cg-mid-grey-3,
    button-color: $cg-mid-grey-8,
    button-background-hover-color: $cg-light-grey-6,
    button-icon-color: $cg-mid-grey-6,
    button-disabled-border: $cg-light-grey-6,
    button-disabled-raised-color: $kyd-orange-1,
    button-disabled-font-color: $cg-mid-grey-3,
    button-focus-raised-outline: $kyd-orange-3,
    icon-bg-color: $kyd-orange-1,
    progress-bg-color: $kyd-orange-1,
    input-selected-border-color: $kyd-orange-4,
    button-icon-light-color: $cg-mid-grey-6,
    button-accent-border: $cg-red-6,
    button-accent-color: $cg-red-6,
    button-accent-background-hover-color: $cg-red-1,
    button-disabled-accent-color: $cg-red-1,
    header-background-color: $cg-white,
    header-dropdown-menu-background-color: $cg-light-grey-1,
    header-dropdown-menu-border-color: $cg-light-grey-6,
    header-menu-item-text-color: $cg-mid-grey-5,
    header-menu-item-text-color-active: $cg-dark-grey-6,
    header-username-text-color: $cg-dark-grey-6,
    header-company-text-color: $cg-mid-grey-7,
    header-hex-box-color-hover: $kyd-orange-6,
    header-hex-box-background-color: $kyd-orange-1,
    header-hex-box-text-color: $kyd-orange-6,
    header-border-color: $cg-light-grey-6,
    sub-header-border-color: $cg-light-grey-6,
    header-logo: $cg-kyd-logo,
    dx-menu-item-selected: $kyd-orange-3,
    dx-menu-item-hover: $kyd-orange-1
  )
);
$displays: (
  "block": block,
  "flex": flex,
  "iblock": inline-block,
  "iflex": inline-flex
);
$buttonMinWidth: 120px;
$buttonHeight: 40px;
$buttonBorderRadius: 3px;
// $buttonFontFamily: OpenSans-Regular;
$checkboxSize: 20px;
$checkboxLabelFontSize: 15px;
$checkboxLabelLineHeight: 22px;
$checkboxLabelFontWeight: bold;
$checkboxLabelColor: #424242;
$checkboxLabelColorDisabled: $cg-light-grey-3;
$checkboxContainerMargin: 10px;
$breadcrumbsColor: $cg-mid-grey-6;
$breadcrumbsHoverColor: #0d3869;
$breadcrumbsSize: 14px;
$breadcrumbsLineHeight: 18px;
$inputPlaceholderColor: $cg-mid-grey-6;
$inputColor: $cg-dark-grey-6;
$inputBorderRadius: 4px;
$inputFontSize: 15px;
$inputLineHeight: 22px;
$dialogBackdropColor: rgba(0, 0, 0, 0.5);
$dialogBorderRadius: 3px;
$dialogTitleColor: #4b4b4b;
$dialogSizeMap: (
  "small": (
    medium-up: 480px,
    small-only: calc(100vw - 48px)
  ),
  "middle": (
    medium-up: 725px,
    small-only: calc(100vw - 48px)
  ),
  "wide": (
    medium-up: 940px,
    small-only: calc(100vw - 48px)
  ),
  "full": (
    medium-up: 1200px,
    small-only: calc(100vw - 48px)
  )
);
$iconWidth: 30px;
$iconWidthSmall: 15px;
$iconHeight: 30px;
$iconHeightSmall: 15px;
$iconAtlasPath: "../../../assets/images/sprites/icons.svg";
$iconAtlasPathSmall: "../../../assets/images/sprites/icons_small.svg";
$iconBackgroundSize: 190px 2310px;
$iconBackgroundSizeSmall: 105px 210px;

// To add new items use URL-encoder for SVG for example: https://yoksel.github.io/url-encoder/ or this https://codepen.io/jakob-e/pen/doMoML; read more here https://codepen.io/tigt/post/optimizing-svgs-in-data-uris; and replace fill of content for fill='${color}'
$iconsData: (
  delete:
    "data:image/svg+xml,%3Csvg width='14' height='18' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 .21h13.78v16.75H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(0 .1)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M6.89 2.79H8.7c.03 0 .04 0 .04-.04V2.4a.94.94 0 0 0-.93-.88H5.96a.92.92 0 0 0-.89.7c-.05.17-.03.34-.04.52 0 .04.01.05.05.05h1.8m-4.37 7.46v4.88c.06.33.3.54.64.54h7.48c.08 0 .15 0 .22-.03.26-.1.42-.33.42-.64V5.42c0-.04 0-.06-.05-.05-1.13 0-2.26-.01-3.4.01-.7.02-1.43 0-2.14 0-1.04-.03-2.08-.01-3.12-.01-.05 0-.05.01-.05.06v4.82M0 5.37V4.08h.58c.04 0 .05-.01.05-.06v-.6c0-.14.04-.26.12-.37a.63.63 0 0 1 .53-.26H3.7c.03 0 .04-.01.04-.05v-.2A2.35 2.35 0 0 1 5.33.29L5.62.2h2.53l.01.01A2.28 2.28 0 0 1 10 2.02c.05.24.05.48.05.72 0 .04.01.05.05.05h2.42c.37 0 .64.28.64.66v.58c0 .04.01.05.05.05h.52c.04 0 .06 0 .06.05v1.18c0 .05-.02.06-.06.06H12.6c-.05 0-.07 0-.07.06v9.55c0 .14 0 .29-.03.43a1.9 1.9 0 0 1-1.84 1.55H3.14a1.93 1.93 0 0 1-1.88-1.97V5.4c0-.04-.02-.04-.05-.04H0' fill='${color}' mask='url(%23b)'/%3E%3C/g%3E%3Cpath d='M3.76 10.63V6.82c0-.05.01-.06.06-.06h1.14c.04 0 .05 0 .05.05v7.63c0 .04 0 .05-.05.05H3.81c-.04 0-.05-.01-.05-.05v-3.81M7.52 10.63v3.8c0 .05-.02.06-.06.06H6.32c-.05 0-.06-.01-.06-.06V6.81c0-.03 0-.05.05-.05h1.16c.04 0 .05.02.05.05v3.82M8.77 10.63V6.82c0-.05.01-.06.06-.06h1.14c.04 0 .05 0 .05.05v7.63c0 .04 0 .05-.05.05H8.82c-.04 0-.05-.01-.05-.06v-3.8' fill='${color}'/%3E%3C/g%3E%3C/svg%3E",
  edit:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='18' viewBox='0 0 528.9 528.9' style='enable-background:new 0 0 528.899 528.899' xml:space='preserve'%3E%3Cpath d='m328.9 89.1 107.6 107.6L164 469.1 56.6 361.5 328.9 89zm189.2-26-48-47.9a47.6 47.6 0 0 0-67.2 0l-46 46 107.6 107.5 53.6-53.6a36.7 36.7 0 0 0 0-52zM.3 512.8c-2 8.8 6 16.7 14.8 14.6L135 498.2 27.5 390.6.3 512.6z' fill='${color}'/%3E%3C/svg%3E",
  closed:
    "data:image/svg+xml,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 12.69h7.55V0H0z'/%3E%3C/defs%3E%3Cg transform='rotate(-180 4 6.5)' fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M7.12 0c-.04.04-.01.07.03.08.3.1.4.32.4.6 0 .22-.14.36-.28.5L5.34 2.95 1.81 6.21c-.11.1-.12.16 0 .27l5.46 5.03c.18.17.29.35.28.6 0 .36-.6.73-.95.51-.26-.16-.46-.36-.67-.56C4.06 10.36 2.2 8.64.35 6.93c-.2-.17-.37-.34-.35-.63a.58.58 0 0 1 .2-.4l3.18-2.93L6.34.24c.07-.06.13-.13.23-.15.04-.01.1-.03.06-.09h.49Z' fill='${color}' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E",
  user:
    "data:image/svg+xml,%3Csvg width='18' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 15.8c0 .99-.31 1.77-.93 2.34-.63.57-1.45.86-2.48.86H3.4a3.52 3.52 0 0 1-2.48-.86A3.03 3.03 0 0 1 0 15.8a12.21 12.21 0 0 1 .56-3.97c.14-.42.32-.82.55-1.21.23-.4.5-.73.8-1 .3-.28.66-.5 1.09-.66.43-.17.9-.25 1.42-.25.08 0 .26.09.54.26l.95.6a6.14 6.14 0 0 0 6.18 0l.95-.6c.28-.17.46-.26.54-.26.52 0 1 .08 1.42.25.43.16.8.38 1.1.66.3.27.56.6.79 1 .23.4.41.8.55 1.2A12.23 12.23 0 0 1 18 15.8ZM13.9 4.75a4.5 4.5 0 0 1-1.43 3.36A4.81 4.81 0 0 1 9 9.5a4.81 4.81 0 0 1-3.47-1.4 4.5 4.5 0 0 1-1.44-3.35 4.5 4.5 0 0 1 1.44-3.36A4.81 4.81 0 0 1 9 0c1.36 0 2.51.46 3.47 1.4a4.5 4.5 0 0 1 1.44 3.35Z' fill='${color}'/%3E%3C/svg%3E",
  quick-guide:
    "data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 598.3 598.2' style='enable-background:new 0 0 598.3 598.2' xml:space='preserve'%3E%3Cstyle%3E.st0%7Bfill:${color}%7D%3C/style%3E%3Cpath class='st0' d='M299.8 0A299.7 299.7 0 0 0 0 298.6a299.9 299.9 0 0 0 299 299.6 299.9 299.9 0 0 0 299.3-298.4A299.7 299.7 0 0 0 299.8 0zm-.8 555.2A256.2 256.2 0 0 1 42.9 294.6 255.9 255.9 0 0 1 299 43.1 255.4 255.4 0 0 1 555.2 300c0 141-114.9 255.2-256.2 255.2z'/%3E%3Cpath class='st0' d='M351.4 128a119.3 119.3 0 0 0-82-12.2c-44.2 8.4-71.5 36.2-85.7 77.8-5.9 17.3-5.2 35.6-5.8 53.6-.1 4.1 1.2 5.1 5.1 5 16.7-.1 33.3 0 50 .1 1.6 0 3.2-.2 4.2-.3.8-12.2 1-23.9 2.6-35.3a43.7 43.7 0 0 1 26.6-36.9 66.8 66.8 0 0 1 41.3-4.7 47.8 47.8 0 0 1 30.1 17.1 39.8 39.8 0 0 1-8.3 55.1c-12.6 9.3-25.7 17.8-38.6 26.6a50.5 50.5 0 0 0-24.3 43.3l-.3 70c0 .6.2 1.2.4 2h59.9c0-14.6.4-28.8-.1-42.9a41.5 41.5 0 0 1 19.8-37.3c9-6.2 18.5-11.7 27.1-18.4 27.6-21.4 40.1-49.8 34.9-84.5-5.3-35.8-26.1-61.1-56.9-78.1zM266.3 480.3h60.2V419h-60.2v61.3z'/%3E%3C/svg%3E",
  open:
    "data:image/svg+xml,%3Csvg width='13' height='8' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 12.7h7.6V0H0z'/%3E%3C/defs%3E%3Cg transform='rotate(-90 4 4)' fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M7.1 0c.4.2.4.4.4.7 0 .2 0 .3-.2.5L5.3 3 1.8 6.2v.3l5.5 5 .3.6c0 .4-.6.7-1 .5l-.7-.5L.3 6.9c-.1-.1-.3-.3-.3-.6A2951.5 2951.5 0 0 1 3.4 3l3-2.8.2-.1V0h.5Z' fill='${color}' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E"
);
