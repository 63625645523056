@import "ddd-styles/variables";
@import "ddd-styles/mixins";
@import "ddd-styles/functions";

.cg-expansion-panel {
  margin-bottom: 10px;
  box-shadow: none;
  border: 1px solid $cg-mid-grey-3;
  position: relative;
  border-radius: 4px;

  &:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .panel-header {
    position: relative;
    padding: 0 45px 0 54px;
    font-weight: 500;
    text-transform: capitalize;

    &:before {
      font: 18px tabler-icons;
      content: "\ea61"; //chevron-right
      position: absolute;
      left: 23px;
      top: 50%;
      margin-top: -9px;
      line-height: 18px;
    }
    &:after {
      content: "";
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      border: 1px solid $cg-light-grey-6;
      border-radius: 50%;
    }
  }

  &.mat-expansion-panel {
    .mat-expansion-panel-header {
      height: auto;
      min-height: 67px;
      padding: 0;
    }

    &:not(.mat-expanded) {
      .mat-expansion-panel-header:not([aria-disabled="true"]) {
        &:hover {
          background: $cg-light-grey-3;
        }
      }
    }
  }

  .mat-expansion-panel-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: $cg-dark-grey-6;
    height: auto;
    min-height: 67px;
  }

  &.mat-expansion-panel-spacing {
    margin: 0 0 4px;
  }

  &.mat-expanded {
    .panel-header {
      &:before {
        margin-top: -9px;
        content: "\ea5f"; //chevron-down
      }
    }
  }

  .panel-header {
    &:before {
      @include themify() {
        color: themed("old-design-main-color");
      }
    }
  }

  &.mat-expanded {
    .panel-header {
      color: $cg-dark-grey-6;
      &:before {
        @include themify() {
          color: themed("old-design-main-color");
        }
        margin-top: -9px;
        content: "\ea5f"; //chevron-down
      }
    }
  }
}

.mat-accordion {
  .mat-expansion-panel {
    &.cg-expansion-panel {
      &:not(.mat-expanded, .mat-expansion-panel-spacing) {
        border-radius: 4px;
      }
    }
  }
}
