@import "../../variables";
@import "../../mixins";
@import "./variables";

%ddd-icon-base {
  background-color: transparent;
  cursor: pointer;
}

.ddd-icon {
  @extend %ddd-icon-base;
  width: $iconWidth;
  height: $iconHeight;
  min-width: $iconWidth;
  background-image: url($iconAtlasPath);
  background-size: $iconBackgroundSize;

  @each $name, $options in $positions {
    $yPos: -(
        map-get(
            $map: $options,
            $key: y
          ) *
          $yStepSize
      );
    @each $color in map-get($map: $options, $key: colors) {
      &.#{$name}-#{"" + $color} {
        background-position: #{map-get($map: $xMap, $key: $color)}px #{$yPos}px;
      }
    }
  }
}

.ddd-icon-small {
  @extend %ddd-icon-base;
  width: $iconWidthSmall;
  height: $iconHeightSmall;
  min-width: $iconWidthSmall;
  background-image: url($iconAtlasPathSmall);
  background-size: $iconBackgroundSizeSmall;

  @each $name, $options in $positionsSmall {
    $yPos: -(
        map-get(
            $map: $options,
            $key: y
          ) *
          $yStepSizeSmall
      );
    @each $color in map-get($map: $options, $key: colors) {
      &.#{$name}-#{"" + $color} {
        background-position: #{map-get($map: $xMapSmall, $key: $color)}px #{$yPos}px;
      }
    }
  }
}

