@use "ddd-styles/variables";
@import "styles-reset";

@import "~dragula/dist/dragula.css";
@import "../node_modules/ngx-toastr/toastr.css";

@import "@angular/material/theming";
@import "~@ng-select/ng-select/themes/default.theme.css";

@include mat-core();

@import "styles-reset.scss-theme";
@import "styles-typography";

@import "app/shared/components/common-header/common-header.component.scss-theme";

$tp-config: ddd-get-typography-config-sans();
@include angular-material-typography($tp-config);

@mixin custom-components-theme($theme) {
  @include ddd-styles-reset-theme($theme);

  /* component's themes might go here */
}

/* Material default theme */
@import "./styles-ddd-theme.scss";

/* ddd rebranding */
@import "./styles-devextreme-overrides.scss";
@import "./styles-material-overrides.scss";
@import "./styles-tabler-icons.scss";

html,
body {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  color: $cg-dark-grey-6;
}

html {
  height: 100%;
  font-family: mat-font-family($tp-config);
  overflow-anchor: none;
  font-size: 14px;
}

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  min-height: 100%;
  padding: 0 55px;
  position: relative;
  font-size: 1rem;
}

a {
  color: $cg-dark-grey-6;
  text-decoration: underline;
}

a:hover {
  color: $cg-dark-grey-5;
}

/* Hide ms-clear on IE and Edge*/
::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

/* Styles for video block */
.video-block {
  max-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Styles for custom forms */
.custom-form {
  .input-form {
    border-radius: 4px;
    color: $cg-dark-grey-6;
    background-color: #fff;
    height: 40px;
    outline: none;
    padding: 0 30px;
    width: 100%;
    max-width: 294px;
    margin-bottom: 4.5px;
  }

  .select-form {
    border-radius: 4px;
    outline: none;
    width: 100%;
    max-width: 294px;
    margin-bottom: 4.5px;
  }

  input,
  textarea,
  select {
    font-size: 14px;
  }

  textarea {
    resize: vertical;
  }

  textarea {
    font-family: Haffer;
  }

  ::-webkit-input-placeholder {
    font-family: Haffer;
  }
  :-moz-placeholder {
    font-family: "Haffer";
  }
  ::-moz-placeholder {
    font-family: "Haffer";
  }
  :-ms-input-placeholder {
    font-family: "Haffer";
  }

  .phone-number-input-container {
    display: flex;
    max-width: 445px;

    .select-form {
      margin-right: 1%;
      white-space: pre-wrap;
      border: none;
    }

    .input-form {
      max-width: 40%;
    }
  }
  &.cg-form {
    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
      font-family: Haffer, sans-serif;
      display: inline-block;
      color: variables.$cg-dark-grey-6;
    }

    .input-form {
      padding-left: 11px;
      max-width: 100%;
    }
  }
  .separator {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid variables.$cg-light-grey-6;
  }

  .form-separator-title {
    color: variables.$cg-dark-grey-6;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .created-by-label {
    display: flex;
    min-height: 40px;
    align-items: center;
    font-size: 14px;
  }
}

.custom-form-section {
  min-height: 100%;
  width: 100%;
  background: url("/assets/images/main/shape-bottom.png") no-repeat right 0 bottom 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Styles for setup pages */
.setup-form {
  .input-form {
    max-width: 445px;
    margin-bottom: 0;
    padding: 0 24px;
  }

  .select-form {
    max-width: 445px;
    margin-bottom: 0;
  }

  .ng-select {
    max-width: 445px;
    outline: none;

    &.ng-select-focused:not(.ng-select-opened) {
      & > .ng-select-container {
        box-shadow: none;
      }
    }

    .ng-select-container {
      background-color: #fff;
      color: #000;
      border: 1px solid $cg-mid-grey-3;
      border-radius: 4px;

      .ng-value-container {
        padding-left: 24px;

        .ng-input {
          padding-left: 24px;
          top: 7px;
        }
      }
    }

    &.ng-select-opened {
      & > .ng-select-container {
        background-color: #e9e9e9;
      }
    }
  }

  .buttons-group {
    display: flex;
    justify-content: flex-end;
    margin: 25px 95px 25px 0;
  }

  .label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 25%;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    padding-right: 22px;
    &.question-label {
      flex-basis: 38%;
      height: auto;
    }
    &.readonly {
      color: variables.$cg-mid-grey-7;
    }
  }

  .control-box {
    flex-basis: 62%;
  }

  .control-group {
    min-height: 40px;
    display: flex;
    margin-bottom: 5px;
  }
}

.mat-mdc-dialog-container {
  color: $cg-dark-grey-6;
  .setup-form {
    .input-form,
    .select-form,
    .ng-select,
    .phone-number-input-container {
      max-width: 100%;
    }

    .phone-number-input-container {
      .select-form {
        max-width: 59%;
      }

      .input-form {
        max-width: 40%;
      }
    }
  }
}

.instruction {
  margin-top: 53px;

  .title {
    font-size: 15px;
    font-weight: 500;
  }

  .list {
    font-size: 16px;
    font-weight: 500;
  }

  .sub-text {
    color: #424242;
    font-size: 15px;
  }

  .sub-step {
    padding-left: 35px;
    line-height: 18px;
    // background: url('/assets/images/setup/info.svg') no-repeat left;
  }

  > div {
    margin-bottom: 25px;
  }
}

.page-title {
  color: variables.$cg-dark-grey-6;
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 24px;
  text-transform: capitalize;
  &.custom-sections {
    margin-top: 53px;
  }
  &.no-capitalize {
    text-transform: none;
  }
}

.page-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: variables.$cg-mid-grey-6;
}

.page-header {
  margin-bottom: 24px;
  &.page-header-with-sides {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page-title {
    margin-bottom: 6px;
  }
  .page-header-right-side {
    margin-left: 20px;
  }
}

[type="radio"] {
  &:checked {
    position: absolute;

    & + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        border: 1px solid #979797;
        border-radius: 100%;
        background: #fff;
      }

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  &:not(:checked) {
    position: absolute;

    & + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        border: 1px solid #979797;
        border-radius: 100%;
        background: #fff;
      }

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
    }
  }
}

.general-section {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto 135px;

  .content-box {
    width: 100%;
  }

  .title-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      word-break: break-all;
    }
  }

  /* Styles for section mat accordion */
  .section-expansion-panel {
    margin-bottom: 10px;
    position: relative;
    border: 1px solid variables.$cg-mid-grey-3;
    border-radius: 4px;

    &.internal-section-expansion-panel {
      margin-bottom: 4px;
      .panel-header {
        font-weight: 400;
      }
    }
    &:not(.mat-expanded) {
      .mat-expansion-panel-header {
        &:not([aria-disabled="true"]) {
          &:hover {
            background: transparent;
          }
        }
      }
    }
    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
      background: transparent;
    }

    .logged-user {
      font-weight: 600;
    }

    .mat-expansion-panel-header {
      height: auto;
      min-height: 67px;
      padding: 0;

      &:not([aria-disabled="true"]) {
        &:hover {
          background: transparent;
        }
      }
    }

    .mat-expansion-panel-header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: variables.$cg-dark-grey-6;
      height: auto;
      min-height: 67px;
    }

    &.mat-expansion-panel-spacing {
      margin: 0 0 4px;
    }

    &.mat-expanded {
      .panel-header {
        &:before {
          margin-top: -9px;
          content: "\ea5f"; //chevron-down
        }
      }
    }

    &.mat-expanded {
      .group-expansion-panel {
        .panel-header.group-panel-header {
          &:before {
            margin-top: -9px;
            content: "\ea61"; //chevron-right
          }
        }

        &.mat-expanded {
          .panel-header.group-panel-header {
            &:before {
              margin-top: -9px;
              content: "\ea5f"; //chevron-down
            }
          }
        }
      }
      .question-expansion-panel {
        .panel-header.question-panel-header {
          &:before {
            margin-top: -9px;
            content: "\ea61"; //chevron-right
          }
        }

        &.mat-expanded {
          .panel-header.question-panel-header {
            &:before {
              margin-top: -9px;
              content: "\ea5f"; //chevron-down
            }
          }
        }
      }
    }

    .group-item {
      border: 1px solid variables.$cg-mid-grey-3;
      background: #fff;
      margin-bottom: 4px;
      padding: 10px;
      z-index: 100;
      &.internal-group-item {
        padding: 0;
      }
    }

    .group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      padding: 12px 0;
      margin-bottom: 3px;

      .label {
        color: #424242;
        font-weight: 500;
      }
    }

    .help-text {
      padding: 0 0 10px 0;
      border: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: variables.$cg-mid-grey-7;
    }

    .question-item {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 3px;

      .question {
        color: #000000;
        /* IE11 */
        flex: 90%;
        margin-right: 1%;
      }
      &.custom-question-item {
        padding: 12px 11px 12px 17px;
        border: 1px solid variables.$cg-mid-grey-3;
        border-radius: 4px;
        &.no-p-bottom {
          padding-bottom: 0;
        }
      }
    }

    .question-content {
      padding: 12px 11px 12px 17px;
      border: 1px solid variables.$cg-mid-grey-3;
      border-radius: 4px;
      &.no-p-bottom {
        padding-bottom: 1px;
      }
      &.send-error {
        border: 2px solid variables.$cg-red-6;
        .question-header {
          .title {
            color: variables.$cg-red-6;
          }
        }
      }
    }

    .panel-header {
      position: relative;
      padding: 0 16px 0 54px;
      font-weight: 500;
      text-transform: capitalize;

      &:before {
        font: 18px tabler-icons;
        content: "\ea61"; //chevron-right
        position: absolute;
        left: 23px;
        top: 50%;
        margin-top: -9px;
        line-height: 18px;
      }
      &:after {
        content: "";
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        border: 1px solid variables.$cg-light-grey-6;
        border-radius: 50%;
      }
    }
    &.show-validation-alert {
      border: 1px solid variables.$cg-red-6;
    }
  }

  /*Styles for guide mat accordion */
  .guide-expansion-panel {
    background-color: #fff;

    .mat-expansion-panel-header {
      height: 67px;
      padding: 0;

      &:not(.mat-expanded) {
        .mat-expansion-panel-header:not([aria-disabled="true"]) {
          &:hover {
            background: $cg-light-grey-3;
          }
        }
      }
    }

    .guide-panel-header {
      position: relative;
      padding: 0 40px 0 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      height: 67px;
      text-transform: capitalize;

      .quick-guide-icon {
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        min-width: 28px;
        border-radius: 50%;
      }
    }

    .guide-item {
      color: #000;
      font-size: 15px;
      line-height: 18px;
      padding-left: 15px;

      & > div {
        margin-bottom: 3px;
        position: relative;
      }
      .step {
        padding-left: 15px;
        &:before {
          content: "-";
          position: absolute;
          left: 5px;
        }
        &.rounded {
          &:before {
            content: "•";
          }
        }
      }
    }
  }

  /*Styles for colleagues list */
  .colleagues-list {
    .title-section {
      display: flex;
      font-family: "Haffer";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: variables.$cg-mid-grey-7;
    }

    .add {
      padding-left: 21px;
      margin-top: 15px;
      &.assign {
        padding-left: 17px;
      }
    }

    .colleagues-list-actions {
      margin: 16px 0;
    }
  }
}

/* Styles for global mat mat expansion panel */
mat-expansion-panel {
  &.mat-expansion-panel {
    box-shadow: none;
    border: 1px solid variables.$cg-mid-grey-3;
    border-radius: 4px;
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }
    &.show-validation-alert {
      border: 1px solid variables.$cg-red-6;
    }
  }
}

.add-item {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
}

.empty-message {
  font-size: 13px;
}

.colleague-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 11px 7px 11px 18px;
  font-weight: 300;
  margin-bottom: 4px;
  border: 1px solid variables.$cg-mid-grey-3;
  border-radius: 4px;
  cursor: move;
  .colleague-item-box {
    overflow: hidden;
  }
  .name {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .email {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .name,
  .email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .colleague-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .tabler-icon {
      cursor: pointer;
    }
  }

  &.gu-transit {
    display: none !important;
  }
}

/*Styles for chips */
.mat-mdc-chip-listbox {
  outline: none;
  &.mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      margin-left: 0;
    }
  }
}

.mat-mdc-chip {
  height: 20px;
  padding: 2px 10px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin-right: 5px;
  display: inline-block;
  width: max-content;

  &.mat-draft {
    background: variables.$cg-light-grey-6;
    color: variables.$cg-mid-grey-8;
  }
  &.mat-rejected,
  &.mat-overdue,
  &.mat-high {
    background: variables.$cg-red-1;
    color: variables.$cg-red-8;
  }

  &.mat-new {
    background: variables.$cg-turquoise-3;
    color: variables.$cg-turquoise-8;
  }

  &.mat-approved,
  &.mat-closed,
  &.mat-low {
    background: variables.$cg-green-1;
    color: variables.$cg-green-8;
  }

  &.mat-empty {
    background: variables.$cg-light-grey-6;
    color: variables.$cg-mid-grey-8;
  }

  &.mat-pending-approval,
  &.mat-open,
  &.mat-unnassigned,
  &.mat-medium {
    background: variables.$cg-orange-1;
    color: variables.$cg-orange-8;
  }

  &.mat-critical {
    background: variables.$cg-red-3;
    color: variables.$cg-red-9;
  }

  &.mat-rejected {
    margin-right: 0;
  }

  &.mat-not-applicable {
    background: variables.$cg-turquoise-1;
    color: variables.$cg-turquoise-8;
  }

  &.mat-static-width {
    width: 70px;
    text-align: center;
  }
}

/* Styles for progress bar */
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: none;
  width: 100%;
  height: 8px;
  margin-top: 42px;
  color: variables.$cg-green-6;

  /* For IE10 */
  &::-webkit-progress-bar {
    border: 0;
    border-radius: 20px;
    background-color: variables.$cg-light-grey-6;
  }

  &::-webkit-progress-value {
    border: 0;
    border-radius: 20px;
    background-color: variables.$cg-green-6;
  }

  /* For firefox */
  &::-moz-progress-bar {
    border: 0;
    border-radius: 20px;
    background-color: variables.$cg-green-6;
  }
}

/*Styles for disabled datepicker*/
.mat-mdc-input-element:disabled {
  color: rgba(0, 0, 0, 0.5);
}

/*Style for template name container*/
.cdk-overlay-container {
  .mat-mdc-menu-panel {
    max-width: 100%;
  }
}

/*Style for edit block on template manger page*/
button {
  font-family: mat-font-family($tp-config);
  &.edit {
    background: transparent;
    border: 0;
  }
}

.btn-select {
  background: none;
  border: none;
  color: $cg-dark-grey-6;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  position: relative;
  padding-right: 20px;

  &:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $cg-dark-grey-6;
  }
}

/* Styles for custom checkbox */
.checkbox-box {
  display: flex;
  align-items: center;
  gap: 6px;
}

.checkbox-custom {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 20px;
  cursor: pointer;
  z-index: 5;
  margin: 0;

  &:disabled {
    & + span {
      &.filter {
        color: $cg-mid-grey-3;
      }
    }
  }

  & + span {
    position: relative;
    padding: 0;
    &.filter {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      color: variables.$cg-dark-grey-6;
    }
  }
}

.checkbox-custom-right {
  &:checked {
    & + span {
      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 10px;
        height: 10px;
        border: none;
        transform: translate(-5px, -5px);
        z-index: 1;
      }
    }
    &:disabled {
      & + span {
        &:after {
          box-shadow: none;
          background: variables.$cg-light-grey-3;
        }
      }
    }
  }

  &:disabled {
    & + span {
      &:after {
        box-shadow: none;
        background: variables.$cg-light-grey-3;
      }
    }
  }

  & + span {
    &:after {
      content: "";
      margin-left: 5px;
      display: inline-block;
      vertical-align: text-bottom;
      width: 20px;
      height: 20px;
      border: 1px solid variables.$cg-mid-grey-3;
    }
  }

  &.checkbox-rounded {
    &:checked {
      & + span {
        &:before {
          border-radius: 10px;
        }
      }
    }

    & + span {
      &:after {
        border-radius: 15px;
      }
    }

    &.auditor-rating-selected + span {
      &:after {
        background-color: variables.$cg-auditor-selected;
      }
    }
  }

  &.checkbox-rounded-rating-satisfactory {
    &:checked {
      & + span {
        &:before {
          border-radius: 10px;
          background-color: variables.$cg-auditor-selected-satisfactory;
        }
      }
    }

    & + span {
      &:after {
        border-radius: 15px;
      }
    }    
  }

  &.checkbox-rounded-rating-needs-improvement {
    &:checked {
      & + span {
        &:before {
          border-radius: 10px;
          background-color: variables.$cg-auditor-selected-needs-improvement;
        }
      }
    }

    & + span {
      &:after {
        border-radius: 15px;
      }
    }
  }

  &.checkbox-rounded-rating-unsatisfactory {
    &:checked {
      & + span {
        &:before {
          border-radius: 10px;
          background-color: variables.$cg-auditor-selected-unsatisfactory;
        }
      }
    }

    & + span {
      &:after {
        border-radius: 15px;
      }
    }
  }
}

.checkbox-custom-left {
  &:checked {
    & + span {
      &:after {
        content: "";
        position: absolute;
        left: 10px;
        bottom: 0;
        width: 10px;
        height: 10px;
        border: none;
        transform: translate(-5px, -5px);
      }
    }
  }

  & + span {
    &:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-bottom;
      width: 20px;
      height: 20px;
      border: 1px solid variables.$cg-mid-grey-3;
    }
  }

  &.checkbox-rounded {
    &:checked {
      & + span {
        &:after {
          border-radius: 10px;
        }
      }
    }

    & + span {
      &::before {
        border-radius: 15px;
      }
    }
  }
}

.text-button {
  margin-right: 10px;
  width: 100px;
  font-size: 13px;
  color: #7f7f80;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.view-responses-button {
  margin-right: 10px;
  width: 100px;
  font-size: 13px;
  color: #7f7f80;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

// Styles for material sort headers for IE fix
.sort-header-container {
  display: flex;
  align-items: center;
  .sort-arrow {
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.custom-select {
  outline: none;
  &.ng-select-focused:not(.ng-select-opened) {
    & > .ng-select-container {
      box-shadow: none;
      border-color: #878787;
    }
  }
  .ng-value {
    span {
      font-size: 13px;
    }
  }
  &.ng-select-disabled {
    .ng-value {
      span {
        color: #b8b8b8;
      }
    }
    .ng-arrow-wrapper {
      &:hover {
        .ng-arrow {
          border-top-color: #999;
        }
      }
    }
  }
  .ng-option {
    font-size: 12px;
  }
}

.hidden-clarifications {
  font-size: 13px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px;
}

.page-table {
  max-height: 90vh;
  overflow: auto;
}

.releaser-auth-dialog-panel {
  .mat-mdc-dialog-container {
    height: auto;
  }
}

.ddd-chart-tooltip {
  font-size: 16px;
  text-align: center;
  span {
    font-weight: bold;
  }
}

/* Styles for progress switch */
.switch-label {
  color: #7c7c7d;
  font-weight: 500;
  font-size: 15px;
}

.switch-box {
  display: flex;
  align-items: center;
}

dx-switch {
  &.dx-switch {
    &.ddd-custom-switch {
      margin-right: 10px;
      height: 16px;
      width: 36px;

      .dx-switch-container {
        border-radius: 30px;
        background: $cg-light-grey-7;
        border-color: transparent;
        width: 100%;
        height: 100%;
      }
      &.dx-state-hover {
        .dx-switch-container {
          background: $cg-light-grey-7;
          border-color: transparent;
        }
        &.dx-switch-on-value {
          .dx-switch-container {
            @include themify() {
              background: themed("old-design-main-color");
            }
          }
        }

        .dx-switch-handle {
          &:before {
            background-color: white;
          }
        }
      }

      &.dx-state-focused {
        .dx-switch-container {
          border-color: none;
        }
        &.dx-state-active {
          .dx-switch-handle {
            &:before {
              background-color: white;
            }
          }
        }
      }

      &.dx-switch-on-value {
        .dx-switch-container {
          @include themify() {
            background: themed("old-design-main-color");
          }
        }
        .dx-switch-handle {
          &::before {
            margin-left: -1px;
          }
        }
      }
      .dx-switch-on,
      .dx-switch-off {
        font-size: 13px;
        font-weight: 200;
        color: white;
        font-size: 0;
      }
      .dx-switch-on {
        text-align: left;
        padding-left: 5px;
      }
      .dx-switch-handle {
        margin-top: 0;
        width: 10px;
        height: 10px;
        flex-basis: 10px;
        &:before {
          border-radius: 50%;
          background-color: white;
          margin-left: 1px;
        }
      }
      .dx-switch-inner {
        align-items: center;
      }
    }
  }
}

.no-data-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px 0;
  .no-data-img {
    height: 100px;
    width: 100px;
    background-image: url("~assets/images/sprites/no-data.svg");
    background-size: 102px 105px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .no-data-title {
    text-align: center;
    color: $cg-mid-grey-6;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
  }
}

.recall-content {
  margin-bottom: 20px;
  padding: 24px;

  .dialog-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: $cg-dark-grey-6;
    margin-bottom: 20px;
  }
}

.gap-14 {
  gap: 14px;
}

.mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
  background-color: $cg-light-grey-3;
}

.checkbox-container {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  border: 0;
  height: 20px;
  width: 20px;
  padding: 0;
  border-radius: 4px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid;
  border-radius: 4px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-container {
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 8px;
    top: 5px;
    width: 4px;
    height: 8px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.checkbox-container-box {
  display: flex;
  margin: 20px 0px;
  align-items: center;
  .checkbox-label {
    margin-left: 10px;
  }
}

ddd-documents-box,
ddd-document-control {
  .upload-box {
    display: flex;
    align-items: flex-start;
  }

  .delete {
    margin: 0 15px 0 5px;
    cursor: pointer;
    &:hover {
      &::before {
        color: variables.$cg-mid-grey-8;
      }
    }
    &::before {
      color: variables.$cg-mid-grey-6;
      font: 16px tabler-icons;
      content: "\eb55"; //icon-x;
    }
  }

  .input-box {
    position: relative;
    height: 36px;
    min-width: 175px;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    background: variables.$cg-white;
    border: 1px solid variables.$cg-light-grey-6;
    border-radius: 28px;
    color: variables.$cg-mid-grey-8;

    &.empty-error {
      border: 2px solid variables.$cg-red-6;
      color: variables.$cg-red-6;
    }

    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      height: 36px;
      width: 100%;
      cursor: pointer;
    }

    &:before {
      color: variables.$cg-mid-grey-8;
      font: 16px tabler-icons;
      content: "\eb47"; //upload icon;
      margin-right: 8px;
    }
    &:hover {
      background-color: variables.$cg-light-grey-6;
    }
  }

  .documents-box {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: variables.$cg-white;
    flex-wrap: wrap;
    padding: 2px 15px 2px 0;
    max-height: 40px;
    position: relative;
    margin-left: 10px;

    .document-count {
      color: variables.$cg-light-grey-1;
      border-radius: 20px;
      font-size: 10px;
      width: 15px;
      text-align: center;
      line-height: 15px;
      position: absolute;
      left: 30px;
      top: 0;
    }
  }

  .document-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 10px;
  }

  .document-progress-box {
    margin: 0 10px;
    width: 50%;

    .document-progress-title {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .doc-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 100%;
    // justify-content: space-between;

    .file-container {
      display: flex;
      align-items: center;
    }

    .doc-name {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 1px solid variables.$cg-light-grey-6;
      border-radius: 28px;
      background-color: variables.$cg-white;
      &:hover {
        background-color: variables.$cg-light-grey-6;
      }
      &::before {
        font: 16px tabler-icons;
        content: "\eaa2"; //file-text icon;
      }
      &.all-documents {
        &::before {
          color: variables.$cg-mid-grey-6;
        }
      }
    }

    span {
      margin-left: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      cursor: pointer;
      word-break: break-all;
      &:not(&.all-documents-link) {
        color: variables.$cg-dark-grey-5;
      }
      &.all-documents-link {
        text-decoration: underline;
      }
    }
  }
}

.actions-table-box {
  position: relative;
  width: 100%;
  height: 100%;

  .action-menu {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -15px;
    display: flex;
    width: 30px;
    flex-direction: row-reverse;
    overflow: hidden;
    &:hover {
      width: auto;
      overflow: auto;
      background-color: variables.$cg-light-grey-3;
      padding: 5px 0;
      margin-top: -20px;
      padding-left: 15px;
      border-radius: 4px;
    }
  }

  .actions-icon {
    display: flex;
  }

  .action-icon {
    &:hover {
      background-color: variables.$cg-light-grey-6;
      border-radius: 4px;
    }
  }
}

.spinner-container {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.32);
  top: 0;
  left: 0;
  z-index: 2000;
}

.question-text-error {
  color: variables.$cg-red-6;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}

// Custom select columns styles
.menu-columns-dropdown {
  border: 1px solid variables.$cg-mid-grey-3;
  position: relative;
  display: inline-block;
  width: 140px;
  user-select: none;
  border-radius: 4px;
  height: 38px;
  label {
    width: 100%;
    padding: 11px 45px;
    color: #000000;
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    background-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .select-open-icon {
    &.closed {
      content: "";
      position: absolute;
      top: 16px;
      right: 14px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #a0a0a0;
    }
    &.opened {
      content: "";
      position: absolute;
      top: 14px;
      right: 14px;
      transform: rotateZ(180deg);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #a0a0a0;
    }
  }
  .options {
    position: absolute;
    top: 38px;
    background-color: #fff;
    width: 100%;
    border: 1px solid variables.$cg-mid-grey-3;
    z-index: 6;
    border-radius: 4px;
    .option-item {
      padding: 10px;
      &:not(:last-child) {
        border-bottom: 1px solid variables.$cg-mid-grey-3;
      }
    }
    .displayed-columns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      &:not(:last-child) {
        border-bottom: 1px solid variables.$cg-mid-grey-3;
      }
      .option-item {
        font-size: 12px;
        border: none;
        max-width: 105px;
      }
    }
  }
}

// Custom styles for mat table filters
.mat-mdc-menu-content,
.mat-mdc-select-panel {
  .clear-filter {
    border: none;
    outline: none;
    background-color: transparent;
    color: variables.$cg-dark-grey-6;
    text-decoration: underline;
    cursor: pointer;
    margin: 8px;
  }
}

.mat-mdc-select-panel {
  padding: 0;
  .checkbox-container {
    margin-left: 10px;
    margin-right: 5px;
  }

  .ddd-button-wrapper {
    flex: 1;
    align-items: center;
  }

  .search-buttons-container {
    padding-bottom: 5px;
    padding-right: 15px;
  }
}
