@use "@angular/material/theming" as matTheming;

/* Haffer Regular */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-Regular.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Regular.woff") format("woff"),
    url("/assets/fonts/Haffer-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Haffer RegularItalic */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-RegularItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-RegularItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Haffer Medium */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-Medium.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Medium.woff") format("woff"),
    url("/assets/fonts/Haffer-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Haffer MediumItalic */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-MediumItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-MediumItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Haffer Bold */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-Bold.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Bold.woff") format("woff"),
    url("/assets/fonts/Haffer-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Haffer BoldItalic */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-BoldItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Haffer Light */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-Light.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Light.woff") format("woff"),
    url("/assets/fonts/Haffer-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Haffer LightItalic */
@font-face {
  font-family: Haffer;
  src:
    url("/assets/fonts/Haffer-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-LightItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-LightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src:
    url("/assets/fonts/Haffer-Regular.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Regular.woff") format("woff"),
    url("/assets/fonts/Haffer-Regular.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src:
    url("/assets/fonts/Haffer-RegularItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-RegularItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-RegularItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src:
    url("/assets/fonts/Haffer-Bold.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Bold.woff") format("woff"),
    url("/assets/fonts/Haffer-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src:
    url("/assets/fonts/Haffer-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-BoldItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-BoldItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src:
    url("/assets/fonts/Haffer-Light.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Light.woff") format("woff"),
    url("/assets/fonts/Haffer-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src:
    url("/assets/fonts/Haffer-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/Haffer-LightItalic.woff") format("woff"),
    url("/assets/fonts/Haffer-LightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@function ddd-get-typography-config() {
  $custom-typography: mat-typography-config(
    $font-family: "Haffer, OpenSans, sans-serif, Arial",
    $headline: mat-typography-level(32px, 48px, 700),
    $body-1: mat-typography-level(16px, 24px, 500)
  );

  @return $custom-typography;
}

@font-face {
  font-family: "OpenSans-Regular";
  src:
    url("/assets/fonts/Haffer-Regular.woff2") format("woff2"),
    url("/assets/fonts/Haffer-Regular.woff") format("woff"),
    url("/assets/fonts/Haffer-Regular.ttf") format("truetype");
  font-display: swap;
}

@function ddd-get-typography-config-sans() {
  $base-typography: matTheming.mat-typography-config(
    $font-family: "Haffer, OpenSans-Regular"
  );

  @return $base-typography;
}
